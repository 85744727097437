import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import JModal from "./JModal";
import { Typography } from "@mui/material";

const ActionItem = (props) => {
  const [anchorel, setAnchorEl] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [confirmActionName, setConfirmActionName] = useState();
  const [confirmKey, setConfirmKey] = React.useState();
  const open = Boolean(anchorel);

  const handleClick = (event) => {
    console.log(props);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onActionItemClick = (actionName, key, showConfirmation, ConfirmationMessage) => {


    if(showConfirmation) {
      setConfirmationMessage(ConfirmationMessage);
      setOpenModal(true);
      setConfirmKey(key);
      setConfirmActionName(actionName);
    }
    else{
      console.log(actionName);
      props.onActionItemClick(actionName, key);
    }
    handleClose();
  }

  const onConfirm = () => {
    props.onActionItemClick(confirmActionName, confirmKey);
    setOpenModal(false);
  }

  const DisplayButton = (action) => {

    return (<Fragment>{action.navLink && (<NavLink
      to={`${action.url}${action.url.includes("?")?'&':'?'}id=${props.id}`}
      style={{
        textDecoration: "none",
        fontSize: "10pt",
        color: "black",
        display: "flex",
        padding: "5px",
      }}
    >
    <Stack direction="row" alignItems="center" spacing={1}>
      <Icon sx={{ color: '#6b7280' }}>
        {action.iconName}
      </Icon>
      <Typography sx={{ fontSize: "10pt",
    fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', }}>          
      {action.name} </Typography>                  
      </Stack> 
    </NavLink>)}

    {!action.navLink && <Button onClick={() => onActionItemClick(action.actionName, props.id, action.showConfirmation, action.confirmationMessage )} sx={{
        textDecoration: "none",
        textTransform: "none",
        fontSize: "10pt",
        color: "black",
        display: "flex",
        padding: "5px",
      }}> 
      <Stack direction="row" alignItems="center" spacing={1}>
      <Icon sx={
        { color: '#6b7280' }
      }>
        {action.iconName}
      </Icon>
      <Typography sx={{ fontSize: "10pt",
    fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', }}>          
      {action.name} </Typography>                  
      </Stack> </Button>}

      </Fragment>) }


  const data = props.data;

  return (
    <Fragment>

      {props.actionItem.length == 1 && (<Fragment><Stack alignItems="center">
        {DisplayButton(props.actionItem[0])}</Stack>
      </Fragment>)}
      
      {props.actionItem.length > 1 && (<Fragment>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id={props.id}
        open={open}
        anchorEl={anchorel}
        aria-labelledby="composition-button"
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* <MenuItem>Hello</MenuItem> */}
        {props.actionItem.map((action) => {
          let children = [];
          let showButton = true;
          let validators = [];
          
          if(action.validator != []) {
            validators = action.validator;
          }

          //========================= VALIDATOR FOR the ITEM =============== 
          {validators.map((validator) => {
            if(validator.type == "=="){
              if (data[validator.fieldname] == validator.value) {
                showButton = false;
              }  
            }
            else if (validator.type == "!=") {
              if (data[validator.fieldname] != validator.value) {
                showButton = false;
              }  
            }     
          })};

          if(showButton) {
          children.push(
            <MenuItem key={action.id}>
              {DisplayButton(action)}              
            </MenuItem>
          );
          }

          return children;
        })}
      </Menu></Fragment>)}

      <JModal openModal={openModal} setOpenModal={setOpenModal} title="Confirmation Message" onConfirm={onConfirm}>
          {confirmationMessage}
      </JModal>
    </Fragment>
  );
};

export default ActionItem;
