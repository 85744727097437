import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import "./App.css";
import Box from "@mui/material/Box";
import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  Suspense,
  useMemo,
} from "react";
import Header from "./components/Layout/Header";
import Drawer from "@mui/material/Drawer";
import FunctionalMenubar from "./components/Layout/SideMenu";
import AuthContext from "./store/auth-context";
import { useStore } from "./hooks-store/store";
import JLoadingPanel from "./components/Layout/JLoadingPanel";
import ConfigApi from "./api/config-api";
import UserApi from "./api/user-api";
import LoginPage from "./components/Pages/LoginPage";
import Vehicle from "./components/Pages/Vehicle";
import Notification from "./components/Layout/Notification";
import ResetPassword from "./components/Pages/ResetPassword";

import PageHeader from "./components/Layout/PageHeader";
import Driver from "./components/Pages/Driver";

import MaintenanceDetail from "./components/Pages/AutoForms/MaintenanceDetail";
import Signal from "./components/Pages/Signal.js";
import { SignalConnection } from "./hooks/signal";




//Lazy Loading
const TripList = React.lazy(() => import("./components/Pages/Trips/TripList"));
const NewTrip = React.lazy(() => import("./components/Pages/Trips/NewTrip"));
const TripDetail = React.lazy(() => import("./components/Pages/Trips/TripDetail"));
const Dashboard = React.lazy(() => import("./components/Pages/Dashboard"));
const ParentScreen = React.lazy(() => import("./components/Pages/AutoForms/ParentScreen"));
const ScreenSub = React.lazy(() => import("./components/Pages/AutoForms/ScreenSub"));
const UserList = React.lazy(() => import("./components/Pages/User/UserList"));
const UserDetail = React.lazy(() => import("./components/Pages/User/UserDetail"));
const ChangePassword = React.lazy(() => import("./components/Pages/User/ChangePassword"));
const RoleList = React.lazy(() => import("./components/Pages/Role/RoleList"));
const RoleDetail = React.lazy(() => import("./components/Pages/Role/RoleDetail"));
const AssignScreen = React.lazy(() => import("./components/Pages/Role/AssignScreen"));
const Company = React.lazy(() => import("./components/Pages/Company/Company"));
const CompanyCurrency = React.lazy(() => import("./components/Pages/Company/CompanyCurrency"));
const CompanyDocument = React.lazy(() => import("./components/Pages/Company/CompanyDocument"));
const IntegrationSetting = React.lazy(() => import("./components/Pages/Company/IntegrationSetting"));
const CompanyList = React.lazy(() => import("./components/Pages/Company/CompanyList"));
const CompanyDetail = React.lazy(() => import("./components/Pages/Company/CompanyDetail"));
const NewPartnerVehicle = React.lazy(() => import("./components/Pages/Partner/PartnerVehicle"));
const PartnerDriver = React.lazy(() => import("./components/Pages/Partner/PartnerDriver"));
const ScheduleView = React.lazy(() =>  import("./components/Pages/Schedule/ScheduleView"));
const ScheduleList = React.lazy(() => import("./components/Pages/Schedule/ScheduleList"));
const DriverSelectScreen = React.lazy(() => import("./components/Pages/Driver/DriverSelectScreen"));
const DriverClaims = React.lazy(() => import("./components/Pages/Driver/DriverClaims"));
const DriverLeave = React.lazy(() => import('./components/Pages/Driver/DriverLeave'));
const DriverSettlement = React.lazy(() => import("./components/Pages/Driver/DriverSettlement"));
const PartnerSelectScreen = React.lazy(() => import("./components/Pages/Partner/PartnerSelectScreen"));
const PartnerSettlement = React.lazy(() => import("./components/Pages/Partner/PartnerSettlement"));
const CorporateList = React.lazy(() => import("./components/Pages/Corporate/CorporateList"));
const CorporateDetail = React.lazy(() => import("./components/Pages/Corporate/CorporateDetail"));
const CorporateSelectScreen = React.lazy(() => import("./components/Pages/Corporate/CorporateSelectScreen"));
const CorporateSettlement = React.lazy(() => import("./components/Pages/Corporate/CorporateSettlement"));
const CorporatePersonInCharge = React.lazy(() => import("./components/Pages/Corporate/CorporatePersonInCharge"));
const ReceiptReport = React.lazy(() => import("./components/Pages/Reports/Receipt"));
const InvoiceReport = React.lazy(() => import("./components/Pages/Reports/Invoice"));
const PayslipReport = React.lazy(() => import("./components/Pages/Reports/Payslip"));
const AuditLog = React.lazy(() => import("./components/Pages/Reports/AuditLog"));
const ProfitReport = React.lazy(() => import("./components/Pages/Reports/ProfitReport"));
const ScheduleReport = React.lazy(() => import("./components/Pages/Reports/ScheduleReport"));
const Location = React.lazy(() => import("./components/Pages/Location/Location"));
const LocationDetail = React.lazy(() => import("./components/Pages/Location/LocationDetail"));
const Holiday = React.lazy(() => import("./components/Pages/Holiday/Holiday"));
const HolidayDetail = React.lazy(() => import("./components/Pages/Holiday/HolidayDetail"));
const VehicleSelectScreen = React.lazy(() => import("./components/Pages/Vehicle/VehicleSelectScreen"));
const VehicleMaintenance = React.lazy(() => import("./components/Pages/Vehicle/VehicleMaintenance"));
const RatePlan = React.lazy(() => import("./components/Pages/RatePlan/RatePlan"));
const RatePlanDetail = React.lazy(() => import("./components/Pages/RatePlan/RatePlanDetail"));
const RatePlanBreakdown = React.lazy(() => import("./components/Pages/RatePlan/RatePlanBreakdown"));
const RatePlanBreakdownLocation = React.lazy(() => import("./components/Pages/RatePlan/RatePlanBreakdownLocation"));
const RateOverview = React.lazy(() => import("./components/Pages/RatePlan/RateOverview"));
const EventRate = React.lazy(() => import("./components/Pages/RatePlan/EventRate"));
const EventRateDetail = React.lazy(() => import("./components/Pages/RatePlan/EventRateDetail"));
const CheckRate = React.lazy(() => import("./components/Pages/RatePlan/CheckRate"));
const PassengerList = React.lazy(() => import("./components/Pages/Passenger/PassengerList"));
const PassengerDetail = React.lazy(() =>  import("./components/Pages/Passenger/PassengerDetail"));
const PassengerSettlement = React.lazy(() => import("./components/Pages/Passenger/PassengerSettlement"));
const ExpensesList = React.lazy(() => import("./components/Pages/Accounting/ExpensesList"));
const ExpensesDetail = React.lazy(() => import("./components/Pages/Accounting/ExpensesDetail"));
const BookingEngineSetting = React.lazy(() => import("./components/Pages/BookingEngine/BESetting"));
const BookingTripTypeSetting = React.lazy(() => import("./components/Pages/BookingEngine/BETripTypeSetting"));
const BEReservation = React.lazy(() => import("./components/Pages/BookingEngine/BEReservation"));
const Unauthorize = React.lazy(() => import("./components/Pages/Unauthorize"));
const FinancialYear = React.lazy(() => import("./components/Pages/Accounting/FinancialYear"));
const FinancialYearDetail = React.lazy(() => import("./components/Pages/Accounting/FinancialYearDetail"));
const JournalList = React.lazy(() => import("./components/Pages/Accounting/JournalList"));
const JournalEntry = React.lazy(() => import("./components/Pages/Accounting/JournalEntry"));
const AccountsList = React.lazy(() => import("./components/Pages/Accounting/AccountsList"));
const AccountsDetail = React.lazy(() => import("./components/Pages/Accounting/AccountsDetail"));

const SupplierSettlement = React.lazy(() => import("./components/Pages/Supplier/SupplierSettlement"));
const SupplierInvoice = React.lazy(() => import("./components/Pages/Supplier/SupplierInvoice"));
const SupplierPayment = React.lazy(() => import("./components/Pages/Supplier/SupplierPayment"));

const drawerWidth = 280;

const drawer = (
  <div>
    {/* <Toolbar />
    <Divider /> */}
    {/* <List>
      {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
        <ListItem key={text} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List> */}
    {/* <Divider />
    <List>
      {["All mail", "Trash", "Spam"].map((text, index) => (
        <ListItem key={text} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List> */}
  </div>
);

function App() {
  const [state, dispatch] = useStore();
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const configApi = ConfigApi();
  const userApi = UserApi();
  const [loadedCompanyID, setLoadedCompanyID] = useState();
  const InitialConnect = SignalConnection();

  useEffect(() => {
    InitialConnect();
  }, []);

  useEffect(() => {
    const getConfig = async () => {
      if (authCtx.isLoggedIn) {
        const result = await configApi.GetConfig(
          authCtx.currentUserID,
          authCtx.companyCode
        );

        if (result.success) {
          dispatch("SetAccessRight", result.data.menu);

          let MenuList = [];
          result.data.menu.map((menu) => {
            MenuList.push({
              menuid: menu.id,
              show: false,
            });

            if (menu.subMenus.length > 0) {
              menu.subMenus.map((submenu) => {
                MenuList.push({
                  menuid: submenu.id,
                  show: false,
                });
              });
            }
          });

          dispatch("SetMenuState", MenuList);
          dispatch("SetCompany", result.data.company);
          dispatch("SetAccessPage", result.data.accessPage);
          dispatch("SetCurrentCompanyID", result.data.selectedCompanyID);
          setLoadedCompanyID(result.data.selectedCompanyID);
          authCtx.setCompanyCode(result.data.selectedCompanyCode);
          dispatch("SetCurrentUserID", authCtx.currentUserID);
          dispatch("SetCurrentUserName", result.data.username);
          dispatch("SetTotalNotification", result.data.totalNotification);
          dispatch("SetNotificationList", result.data.notification);
        }
        else {
          dispatch("ShowNotification", { message: result.message, errormessage: true })
          authCtx.logout();
        }
      }
    };

    getConfig();
  }, [authCtx.token]);

  //make sure the signal R is connecting and connected even after reconnecting.
  //so that can push notification to the client.
  const UserConnection = async () => {
    if (
      state.currentUserID !== null &&
      state.currentUserID !== undefined &&
      state.signalConnectionID !== null &&
      state.signalConnectionID !== undefined &&
      state.currentCompanyID !== null &&
      state.currentCompanyID !== undefined
    ) {
      var response = await userApi.SignalConnect(
        state.currentUserID,
        state.signalConnectionID,
        state.currentCompanyID
      );
    }
  };

  useEffect(() => {
    UserConnection();
  }, [state.currentUserID, state.signalConnectionID, state.currentCompanyID]);

  const handleDrawerToggle = () => {
    // if (mobileOpen) {
    //   setSidebarWidth(0);
    // } else {
    //   setSidebarWidth(drawerWidth);
    // }

    setMobileOpen(!mobileOpen);
  };

  // const TriggerNotification = (
  //   notificationMessage,
  //   showSuccessMessage = true
  // ) => {
  //   setShowNotification(true);
  //   setNotificationMessage(notificationMessage);
  //   setshowSuccessMessage(showSuccessMessage);
  // };

  const onCloseNotification = () => {
    dispatch("ShowNotification", { show: false });
  };

  const LoadPageHeader = useMemo(() => {
    return (
      <PageHeader
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        loadedCompanyID={loadedCompanyID}
        // setSidebarWidth={setSidebarWidth}
      ></PageHeader>
    );
  });

  return (
    <Fragment>
      <JLoadingPanel showLoadingPanel={state.isLoading} />
      {!authCtx.isLoggedIn && (
        <Fragment>
          <Switch>
            <Route path="/resetpassword">
              <ResetPassword />
            </Route>
            <Route path="*">
              <LoginPage />
            </Route>
          </Switch>
        </Fragment>
      )}

      {authCtx.isLoggedIn && (
        <Fragment>
          {LoadPageHeader}
          <Box>
            <Box
              component="nav"
              sx={{
                // width: { md: sidebarWidth },
                top: 0,
                // flexShrink: { sm: 0 },
              }}
              aria-label="mailbox folders"
            >
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                  // display: { sx: "block" },
                  "& .MuiDrawer-paper": {
                    backgroundColor: "#121828",
                    boxSizing: "border-box",
                    width: drawerWidth,
                    top: 50,
                    height: '95%'
                  },
                }}
              >
                <FunctionalMenubar setMobileOpen={setMobileOpen} />
              </Drawer>
            </Box>
            <Box
              component="main"
              sx={{
                display: "static",
                p: { xs: 1, md: 5 },
                pt: { xs: 7, md: 8 },
                // width: "100%" ,
                // md: `calc(100% - ${drawerWidth}px)`
              }}
            >
              <Header />
              <br />

              <Suspense fallback={<p>Loading....</p>}>
                <Switch>
                  <Route path="/dashboard">
                    <Dashboard />
                  </Route>
                  <Route path="/companylist" exact>
                    <CompanyList />
                  </Route>
                  <Route path="/companylist/detail">
                    <CompanyDetail />
                  </Route>
                  {/* <Route path="/vehicle">
                    <Vehicle />
                  </Route> */}
                  {/* <Route path="/driver">
                    <Driver />
                  </Route> */}
                  <Route path="/trip" exact>
                    <TripList />
                  </Route>
                  <Route path="/trip/newtrip" exact>
                    <NewTrip />
                  </Route>
                  <Route path="/trip/tripdetail" exact>
                    <TripDetail />
                  </Route>
                  <Route path="/maintenance/:screen" exact>
                    <ParentScreen />
                  </Route>
                  <Route path="/maintenance/:screen/form">
                    <MaintenanceDetail />
                  </Route>
                  <Route path="/maintenance/:screen/:sub" exact>
                    <ScreenSub />
                  </Route>
                  {/* <Route path="/maintenance/:screen/:sub/form">
                  <MaintenanceDetail />
                </Route> */}
                  <Route path="/passenger" exact>
                    <PassengerList />
                  </Route>

                  <Route path="/passenger/detail" exact>
                    <PassengerDetail />
                  </Route>

                  <Route path="/passenger/detail/location">
                    <LocationDetail />
                  </Route>
                  
                  <Route path="/passenger/detail/trip">
                    <TripDetail />
                  </Route>
                  
                  <Route path="/passenger/settlement" exact>
                    <PassengerSettlement />
                  </Route>

                  <Route path="/driver/:screen" exact>
                    <DriverSelectScreen />
                  </Route>
                  <Route path="/driver/driverclaims/form" exact>
                    <DriverClaims />
                  </Route>

                  <Route path="/driver/driverleave/form" exact>
                    <DriverLeave />
                  </Route>


                  <Route path="/driver/driversettlement/form">
                    <DriverSettlement />
                  </Route>

                  <Route path="/partner/:screen" exact>
                    <PartnerSelectScreen />
                  </Route>

                  <Route path="/vehicle/vehiclemaintenance" exact>
                    <VehicleSelectScreen />
                  </Route>

                  <Route path="/vehicle/vehiclemaintenance/detail" exact>
                    <VehicleMaintenance />
                  </Route>

                  <Route path="/partner/partnervehicle/form">
                    <NewPartnerVehicle />
                  </Route>
                  <Route path="/partner/partnerdriver/form">
                    <PartnerDriver />
                  </Route>

                  <Route path="/partner/partnersettlement/form">
                    <PartnerSettlement />
                  </Route>

                  <Route path="/corporatelist/" exact>
                    <CorporateList />
                  </Route>
                  
                  <Route path="/corporatelist/detail" exact>
                    <CorporateDetail />
                  </Route>

                  <Route path="/corporatelist/detail/location">
                    <LocationDetail />
                  </Route>

                  <Route path="/corporatelist/detail/trip">
                    <TripDetail />
                  </Route>

                  <Route path="/corporate/:screen" exact>
                    <CorporateSelectScreen />
                  </Route>

                  <Route path="/corporate/corporatesettlement/form">
                    <CorporateSettlement />
                  </Route>
                  
                  <Route path="/corporate/personincharge/form">
                    <CorporatePersonInCharge />
                  </Route>

                  <Route path="/supplier/settlement" exact>
                    <SupplierSettlement />
                  </Route>
                  
                  <Route path="/supplier/settlement/invoice">
                    <SupplierInvoice />
                  </Route>

                  <Route path="/supplier/settlement/payment">
                    <SupplierPayment />
                  </Route>

                  <Route path="/companyinfo">
                    <Company />
                  </Route>
                  <Route path="/companycurrency">
                    <CompanyCurrency />
                  </Route>
                  <Route path="/companydocument">
                    <CompanyDocument />
                  </Route>
                  <Route path="/integrationsetting">
                    <IntegrationSetting />
                  </Route>
                  <Route path="/user">
                    <UserList />
                  </Route>
                  <Route path="/userdetail">
                    <UserDetail />
                  </Route>
                  <Route path="/changepassword">
                    <ChangePassword />
                  </Route>
                  <Route path="/role">
                    <RoleList />
                  </Route>
                  <Route path="/roledetail">
                    <RoleDetail />
                  </Route>
                  <Route path="/assignscreen">
                    <AssignScreen />
                  </Route>
                  <Route path="/schedule">
                    <ScheduleView />
                  </Route>

                  <Route path="/schedulelist">
                    <ScheduleList />
                  </Route>

                  <Route path="/invoice">
                    <InvoiceReport />
                  </Route>

                  <Route path="/receipt">
                    <ReceiptReport />
                  </Route>

                  <Route path="/payslip">
                    <PayslipReport />
                  </Route>

                  <Route path="/auditlog">
                    <AuditLog />
                  </Route>

                  <Route path="/profitreport">
                    <ProfitReport />
                  </Route>
                  
                  <Route path="/schedulereport/:screen">
                    <ScheduleReport />
                  </Route>


                  <Route path="/location" exact>
                    <Location />
                  </Route>
                  <Route path="/location/detail">
                    <LocationDetail />
                  </Route>
                  <Route path="/holiday" exact>
                    <Holiday />
                  </Route>
                  <Route path="/holiday/detail">
                    <HolidayDetail />
                  </Route>
                  <Route path="/rateplan" exact>
                    <RatePlan />
                  </Route>
                  <Route path="/rateplan/detail" exact>
                    <RatePlanDetail />
                  </Route>
                  <Route path="/rateplan/breakdown" exact>
                    <RatePlanBreakdown />
                  </Route>
                  <Route path="/rateplan/breakdown/location">
                    <RatePlanBreakdownLocation />
                  </Route>
                  <Route path="/checkrate">
                    <CheckRate />
                  </Route>
                  <Route path="/invoice">
                    <InvoiceReport />
                  </Route>
                  <Route path="/besetting" exact>
                    <BookingEngineSetting />
                  </Route>

                  <Route path="/besetting/triptype">
                    <BookingTripTypeSetting />
                  </Route>

                  <Route path="/bereservation">
                    <BEReservation />
                  </Route>

                  <Route path="/unauthorize">
                    <Unauthorize />
                  </Route>
                  <Route path="/signal">
                    <Signal />
                  </Route>

                  <Route path="/expenses" exact>
                    <ExpensesList />
                  </Route>

                  <Route path="/expenses/detail">
                    <ExpensesDetail />
                  </Route>

                  <Route path="/rateoverview" exact>
                    <RateOverview />
                  </Route>
            
                  <Route path="/eventrate" exact>
                    <EventRate />
                  </Route>

                  <Route path="/eventrate/detail">
                    <EventRateDetail />
                  </Route>

                   
                  <Route path="/financialyear" exact>
                    <FinancialYear />
                  </Route>

                  <Route path="/financialyear/detail">
                    <FinancialYearDetail />
                  </Route>

                  
                   
                  <Route path="/journal" exact>
                    <JournalList />
                  </Route>

                  <Route path="/journal/entry">
                    <JournalEntry />
                  </Route>

                           
                  <Route path="/accounts" exact>
                    <AccountsList />
                  </Route>

                  <Route path="/accounts/form">
                    <AccountsDetail />
                  </Route>



                  <Route path="*">
                    <Redirect to="/"></Redirect>
                  </Route>
                </Switch>
              </Suspense>
            </Box>
          </Box>
        </Fragment>
      )}

      <Notification
        open={state.showNotification.show}
        onCloseNotification={onCloseNotification}
        setNotificationMessage={state.showNotification.message}
        showErrorMessage={state.showNotification.errormessage}
      />
    </Fragment>
  );
}

export default App;
