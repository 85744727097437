import React, { Fragment, useEffect, useState, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { SignalCellularConnectedNoInternet0BarOutlined } from '@mui/icons-material';
import JTextbox from '../Layout/JTextbox';
import JButton from '../Layout/JButton';
import JLabel from '../Layout/JLabel';

const Signal = () => {

    const [connection, setConnection] = useState(null);
    const [ chat, setChat ] = useState([]);
    const [user, setUser] = useState('');
    const [message, setMessage] = useState('');
    const latestChat = useRef(null);

    const url =  process.env.REACT_APP_API_ENDPOINT;
    latestChat.current = chat;

    useEffect(() => {

        const newConnection = new HubConnectionBuilder()
        .withUrl(url + "signalHub")
        .withAutomaticReconnect()
        .build();

        setConnection(newConnection);

    },[]);

    useEffect(() => {

        if(connection) {
            console.log('Back to here?');
            connection.start()
             .then(result => {
                console.log('Connected');
                console.log(connection.connectionId);
                connection.on('ReceiveMessage', message => {

                    const updatedChat = [...latestChat.current];
                    updatedChat.push(message);

                    setChat(updatedChat);

                });

             })
             .catch(e => console.log('Connnection failed ', e));
        }


    },[connection]);

    const sendMessage = async () => {
       
        console.log(connection._connectionStarted); 
        if(connection._connectionStarted) {
            try {

                const commandMessage = {
                    user: user,
                    message: message
                }


                await connection.send('SendMessage', commandMessage);
            }   
            catch(e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet');
        }

    }

    return <Fragment>
        <p>Its me! Signal</p>
        <JTextbox caption="User" value={user} setValue={setUser}></JTextbox>
        <JTextbox caption="Message" value={message} setValue={setMessage}></JTextbox>
        <JButton onClick={sendMessage}>Send Message</JButton>

        {chat.map(m => 
        (<Fragment><JLabel>{m.user}</JLabel>
        <JLabel>{m.message}</JLabel></Fragment>))}
    </Fragment>

}

export default Signal;