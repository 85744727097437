import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
    token: '',
    currentUserID: '',
    language: 'en',
    companyCode: null,
    isLoggedIn: false,
    setCompanyCode: (code) => {},
    changeLanguage: (code) => {},
    login: (token) => {},
    logout: () => {},
   // showNotification: (message, success) => {},   
    CountryList: [],
    CurrencyList: [],
    ScheduleCategoryList: [],
    TripTypeList: [],
    CustomerTypeList: [],
    CommunicateSourceList: [],
    OrderSourceList: [],
    TransportTypeList: [],
    TransitTypeList: [],
    TripStatusList: [],
    CorporateList: [],
    DriverList: [],
    VehicleList: [],
    PartnerList: [],
    GlobalVariable: [],
    PassengerTypeList: [],
    PaymentModeList: [],
    ChargesList: [],
    CommonStatusList: [],
    ClaimsTypeList: [],
    ExpensesTypeList: [],
    BillTypeList: [],
    LanguageList: [],
    VehicleModelList: [],  
    VehicleTypeList: [], 
    GenericLocationList: [],
    TransitLocationList: [],
    AttractionLocationList: [],
    CountryStateList: [],
    LocationTypeList: [],
    LeaveTypeList: [],
    LocationServiceTypeList: []
});

// const ShowNotification = (message, success = true) => {
//   const [dispatch] = useStore()[1];
//   dispatch("ShowNotification", true);
//   dispatch("NotificationMessage", message);
//   dispatch("ShowErrorMessage", success);

// }

const CalculateRemainingTime = (expirationTime) => {
  const CurrentTime = new Date().getTime();
  const AdjExpirationTime = new Date(expirationTime).getTime();

  const RemainingDuration = AdjExpirationTime - CurrentTime;

  return RemainingDuration;
}

const RetrieveStoredData = () =>
{
    const storedToken = localStorage.getItem('token');
    const storedExpirationTime = localStorage.getItem('expirationTime');
    const storedUserID = localStorage.getItem('userId');
    const storedCompanyCode = localStorage.getItem('companyCode');
    const storedLanguage = localStorage.getItem('language');

    const remainingTime = CalculateRemainingTime(storedExpirationTime);

    if(remainingTime <= 3600) {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
      return null;
    }

    return {
      token: storedToken,
      duration: remainingTime,
      userID: storedUserID,
      companyCode: storedCompanyCode,
      language: storedLanguage
    };

}

export const AuthContextProvider = (props) =>{
    //const initialToken = null;

    const [communicateSource, setCommunicateSource] = useState([]);
    const [corporate, setCorporate] = useState([]);
    const [partner, setPartner] = useState([]);
    const [orderSource, setOrderSource] = useState([]);   
    const [vehicle, setVehicle] = useState([]);
    const [driver, setDriver] = useState([]);   
    const [paymentMode, setPaymentMode] = useState([]);  
    const [charges, setCharges] = useState([]);  
    const [expensesType, setExpensesType] = useState([]); 
    const [claimsType, setClaimsType] = useState([]); 
    const [vehicleModel, setVehicleModel] = useState([]);
    const [vehicleType, setVehicleType] = useState([]);
    const [transitLocation, setTransitLocation] = useState([]);
    const [attractionLocation, setAttractionLocation] = useState([]);
    const [genericLocation, setGenericLocation] = useState([]);
    const [countryState, setCountryState] = useState([]);
    const [tripType, setTripType] = useState([]);
    const [locationType, setLocationType] = useState([]);
    const [leaveType, setLeaveType] = useState([]);
    const [locationServiceType, setLocationServiceType] = useState([]);

    const tokenData = RetrieveStoredData();
    let initialToken;
    let initialUserID;
    let initialCompanyCode = null;
    let initialLanguage = 'en';
    
    if(tokenData) {
      initialToken = tokenData.token;
      initialUserID = tokenData.userID;
      initialCompanyCode = tokenData.companyCode;
      initialLanguage = tokenData.language;
    }

    const [token, setToken] = useState(initialToken);
    const [language, setLanguage] = useState(initialLanguage);
    const [currentUserID, setCurrentUserID] = useState(initialUserID);
    const [companyCode, setCompanyCode] = useState(initialCompanyCode);
    const [signalConnectionID, setSignalConnectionID] = useState(null);
    const userIsLoggedIn = !!token;

    const PrepareNewTrip = (props) => {
      console.log("@PrepareNewTrip");
      console.log(props);
      setCommunicateSource(props.communicateSource);
      setCorporate(props.corporate);
      setPartner(props.partner);
      setOrderSource(props.orderSource);
      setVehicle(props.vehicle);
      setDriver(props.driver);
      console.log(props.driver);
      setPaymentMode(props.paymentMode);
      setCharges(props.charges);
      setExpensesType(props.expensesType);
      setClaimsType(props.claimsType);
      setVehicleModel(props.vehicleModels);
      setGenericLocation(props.genericLocation);
      setTransitLocation(props.transitLocation);
      setAttractionLocation(props.attractionLocation);
      setVehicleType(props.vehicleType);
      setCountryState(props.countryState);
      setTripType(props.tripType);
      setLocationType(props.locationType);
      setLocationServiceType(props.locationServiceTypes);
    }

    const companyCodeHandler = (code) => {
      localStorage.setItem('companyCode', code);   
      setCompanyCode(code);
    }

    const changeLanguage = (code) => {

      localStorage.setItem('language', code); 
      setLanguage(code);
  
    };

    const loginHandler = (props) => {

      const expirationTime = new Date(new Date().getTime() + (props.expireDuration * 60 * 1000));
      setToken(props.accessToken);  
      setCurrentUserID(props.userId);
      localStorage.setItem('token', props.accessToken); 
      localStorage.setItem('userId', props.userId);  
      localStorage.setItem('expirationTime', expirationTime); 
      localStorage.setItem('language', 'en');  

      const remainingTime = CalculateRemainingTime(expirationTime);

      //logoutTimer = setTimeout(logoutHandler, remainingTime);

    };

    const logoutHandler = useCallback(() => {
        setToken(null);
        setCurrentUserID(null);
        setCompanyCode(null);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('companyCode');   
     
        
      if(logoutTimer) {
        clearTimeout(logoutTimer);
      }

    }, []);
    
    useEffect(() => 
    {
        if(tokenData) {
          console.log(tokenData.duration);
          //logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler]);


    const Country = [
        {
          countryID: 1,
          countryName: "Malaysia",
        },
        {
          countryID: 2,
          countryName: "Singapore",
        },
        {
          countryID: 3,
          countryName: "Japan",
        },
        {
          countryID: 4,
          countryName: "China",
        },
      ];

    const Currency = [
      {
        currencyID: 1,
        currencyName: "MYR",
      },
      {
        currencyID: 2,
        currencyName: "SGD",
      },
    ]

    const ScheduleCategory = [
      {
        scheduleCategoryID: 1,
        scheduleCategoryName: "Internal",
      },
      {
        scheduleCategoryID: 2,
        scheduleCategoryName: "Partner",
      },
    ]

    const TripType = [
      {
        tripTypeID: 1,
        tripTypeName: "Pick & Drop",
      },
      {
        tripTypeID: 2,
        tripTypeName: "Transit",
      },
      {
        tripTypeID: 3,
        tripTypeName: "Tour",
      },
        // },
      // {
      //   tripTypeID: 4,
      //   tripTypeName: "Car Rental",
      // },
      // {
      //   tripTypeID: 5,
      //   tripTypeName: "Car Pool",
      // },
      // {
      //   tripTypeID: 6,
      //   tripTypeName: "Group Services",
      // },
    ];

    const CustomerType = [
      {
        customerTypeID: 1,
        customerTypeName: "Passenger",
      },
      {
        customerTypeID: 2,
        customerTypeName: "Corporate",
      },
    ];
  
    const TransportType = [
      {
        transportTypeID: 1,
        transportTypeName: "Flight"
      },
      {
        transportTypeID: 2,
        transportTypeName: "Vessel"
      },
      {
        transportTypeID: 3,
        transportTypeName: "Vehicle"
      }
    ]


    const TransitType = [
      {
        transitTypeID: 1,
        transitTypeName: "Arrival"
      },
      {
        transitTypeID: 2,
        transitTypeName: "Departure"
      }
    ]

    const TripStatus = [
      {
        tripStatusID: 1,
        tripStatusName: "Pending"
      },
      {
        tripStatusID: 2,
        tripStatusName: "Confirmed"
      }
    ]

    const Language = [
      {
        code: "en",
        name: "English"
      },
      {
        code: "cn",
        name: "中文"
      }
    ]
 
    const PassengerType = [
      {
      id: 1,
      name: "Existing Passenger"
    },{
      id: 2,
      name: "New Passenger"
    }]

    const CommonStatus = [{
      id: 1,
      name: "Active"
    },{
      id: 0,
      name: "Inactive"
    }]

    const BillType = [{
      id: 1,
      name: "Collect from Passenger"
    },{
      id: 2,
      name: "Collect from Partner"
    },]

    const LeaveType = [{
      id: 1,
      name: "Off Day"
    },{
      id: 2,
      name: "Annual Leave"
    }]

    const globalVariable = {
      TripType_PickandDrop: 1,
      TripType_Transit: 2,
      TripType_Tour: 3,
      TripType_CarRental: 4,
      TripType_CarPool: 5,
      TripType_GroupService: 6,
      CustomerType_Passenger: 1,
      CustomerType_Corporate: 2,
      ScheduleCategory_Internal: 1,
      ScheduleCategory_Partner: 2,
      ScheduleType_Pickup: 1,
      ScheduleType_Dropoff: 2,
      OrderSource_Direct: 1,
      OrderSource_Partner: 2,
      OrderSource_BookingEngine: 3,
      PassengerType_OrderedPassenger: 0,
      PassengerType_ExistingPassenger: 1,
      PassengerType_NewPassenger: 2,
      PassengerType_PassengerPlacard: 3,
      PassengerType_NoNeedContact: 4,
      BillType_Passenger: 1,
      BillType_Channel: 2,
      TripStatus_Pending: 1,
      TripStatus_Confirmed: 2,
      TripStatus_Completed: 3,
      TripStauts_Cancelled: 4,
      AreaType_AnyArea: 1,
      AreaType_SelectedArea: 2,
      LocationType_StateArea: 1,
      LocationType_GenericLocation: 2,
      LocationType_AttractionPlace: 4,
      TransitType_Arrival: 1,
      TransitType_Departure: 2,
    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        currentUserID: currentUserID,
        companyCode: companyCode,
        language: language,
        setCompanyCode: companyCodeHandler,
        changeLanguage: changeLanguage,
        login: loginHandler,
        logout: logoutHandler,
        //showNotification: ShowNotification,
        prepareNewTrip: PrepareNewTrip,
        CountryList: Country,
        CurrencyList: Currency,
        ScheduleCategoryList: ScheduleCategory,
        TripTypeList: tripType,
        CustomerTypeList: CustomerType,
        CommunicateSourceList: communicateSource,
        OrderSourceList: orderSource,
        TransportTypeList: TransportType,
        TransitTypeList: TransitType,
        TripStatusList: TripStatus,
        CorporateList: corporate,
        DriverList: driver,
        VehicleList: vehicle,
        PartnerList: partner,
        GlobalVariable: globalVariable,
        PassengerTypeList: PassengerType,
        PaymentModeList: paymentMode,
        ChargesList: charges,
        CommonStatusList: CommonStatus,
        ExpensesTypeList: expensesType,
        ClaimsTypeList: claimsType,
        BillTypeList: BillType,
        LanguageList: Language,
        VehicleModelList: vehicleModel,
        VehicleTypeList: vehicleType,
        GenericLocationList: genericLocation,
        TransitLocationList: transitLocation,
        AttractionLocationList: attractionLocation,
        CountryStateList: countryState,
        LocationTypeList: locationType,
        LeaveTypeList: LeaveType,
        LocationServiceTypeList: locationServiceType
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;