
import React, {Fragment} from 'react';
import Grid from "@mui/material/Grid";

const JFormLayout = (props) => {


//props.main = true; nested = false
const MainLayout = props.main == null ? true : props.main;

    return <Fragment>
            <Grid container spacing={MainLayout ? 2 : 30} 
            xs={12}>
                {props.children}
            </Grid>
    </Fragment>

}

export default JFormLayout;