import React, { Fragment, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import JDataGrid, { createTableColumn, createActionItem } from '../Layout/JDataGrid';
import JButton from '../Layout/JButton';


const TableColumn = [
    createTableColumn("Full Name", "fullName"),
    createTableColumn("NRIC", "nric"),
    createTableColumn("Nickname", "Nickname"),
    createTableColumn("Main contact", "contactNumberPrimary"),
    createTableColumn("Secondary Contact", "contactNumberSecondary"),
    createTableColumn("Whatsapp ID", "whatsappId", "center"),
    createTableColumn("WeChat ID", "weChatId", "center"),
    createTableColumn("Commission Rate", "commissionRate", "center")
];

const ActionItemData = [
    createActionItem("Edit","/dashboard"),
    createActionItem("Delete","/companylist")
];

const Driver = () => {

    //const { isLoading, error, sendRequest: GetTrip } = useHttp();
    const [data, setData] = useState([]);

    // useEffect(() => {

    //     async function fetchData() {
    //     var response = await GetTrip("Driver/GetDriverList?companyId=1");
    //     console.log(response);
  
    //     if (response.success) {
    //         setData(response.data);
    //     } else {

    //     }   
    // }

    // fetchData();

    // }, []);

     return <Fragment>
       <JDataGrid tableColumn={TableColumn} dataSource={data}  actionItemData={ActionItemData} url="/newdriver" />      
    </Fragment>;
}

export default Driver;