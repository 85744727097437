import React, { useContext, useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AuthContext from "../../store/auth-context";
import { Typography } from "@mui/material";
import JTextbox from "../Layout/JTextbox";
import backgroundImage from "../../assets/background.jpg";
import Grid from "@mui/material/Grid";
import UserApi from "../../api/user-api";
import { useStore } from "../../hooks-store/store";
import JFormLayout from "../Layout/JFormLayout";
import JFormItem from "../Layout/JFormItem";
import Translater from "../../languages/translater";


const myStyle = {
  backgroundImage: `url(${backgroundImage})`,
  height: "100vh",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const LoginPage = (props) => {
  const history = useHistory();
  const [username, UserNameValye] = useState(null);
  const [password, PasswordValue] = useState(null);
  const [recoveryEmail, setRecoveryEmail] = useState();
  const [loginPage, setLoginPage] = useState(true);
  const userApi = UserApi();
  const [state, dispatch] = useStore();
  const translater = Translater();

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log(process.env.ENVIRONMENT);
     if (process.env.REACT_APP_ENVIRONMENT == "DEV") {
      UserNameValye("eiryk878@gmail.com");
      PasswordValue("Teik1102!");
     }
  }, [process.env.ENVIRONMENT]);

  const LoginHandler = async () => {

    if(username == null) {
      dispatch("ShowNotification", {
        message: "Please fill in username",
        errormessage: true,
      });
      return;
    }

    if(password == null) {
      dispatch("ShowNotification", {
        message: "Please fill in password",
        errormessage: true,
      });
      return;
    }


    var response = await userApi.Login(username, password);

    if (response.success) {
      authCtx.login(response.data);
      history.push("/trip");
    } else {
      dispatch("ShowNotification", {
        message: response.message,
        errormessage: true,
      });
    }
  };

  const SendRecoverEmail = async () => {
    console.log(recoveryEmail);
    var response = await userApi.ForgotPassword(recoveryEmail);
    console.log(response);

    if (response.success) {
      dispatch("ShowNotification", {
        message: response.message,
      });
      setLoginPage(true);
    } else {
      dispatch("ShowNotification", {
        message: response.message,
        errormessage: true,
      });
    }
  };

  const SwitchPage = () => {
    setLoginPage(!loginPage);
  };

  return (
    <div style={myStyle}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
        }}
      >
        <Box sx={{ flex: "1 1 auto" }} />
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255,  0.7)",
            height: "100%",
            width: "400px",
            display: "flex",
          }}
        >    
          <Grid container direction="row">
            <Grid
              container
              xs={12}
              justifyContent="flex-end"
              direction="row"
              alignItems="flex-start"
              columnSpacing={10}
            >
              <Typography variant="overline" display="block" gutterBottom>
                Version 1.0
              </Typography>
            </Grid>

            <Grid
              container
              xs={12}
              justifyContent="center"
              direction="row"
              alignItems="flex-start"
            >
              <Grid
                container
                xs={12}
                spacing={2.5}
                columnSpacing={10}
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Vehicle Resource Planning System
                  </Typography>
                </Grid>

                {loginPage && (
                  <Fragment>
                    <Grid item xs={12}>
                      {" "}
                      <JTextbox
                        caption={translater.Translate("Username")}
                        value={username}
                        setValue={UserNameValye}
                      />
                    </Grid>
                    <Grid item xs={12}>          
                      <JTextbox
                        caption={translater.Translate("Password")}
                        value={password}
                        setValue={PasswordValue}
                        isPassword="true"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={LoginHandler} variant="contained">
                        Login
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                       <Button onClick={SwitchPage}>Forgot Password</Button>
                    </Grid>
                  </Fragment>
                )}

                {!loginPage && (
                  <Fragment>
                    <Grid item xs={12}>
                      {" "}
                      <JTextbox
                        caption="Recovery Email"
                        value={recoveryEmail}
                        setValue={setRecoveryEmail}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={SendRecoverEmail} variant="contained">
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12}>        
                      <Button onClick={SwitchPage}>Back to Login</Button>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              justifyContent="flex-end"
              direction="row"
              alignItems="flex-end"
              columnSpacing={10}
            >
              <Typography variant="overline" display="block" gutterBottom>
                Powered By Jinspire Solutions PTE. LTD.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default LoginPage;
