import { SendRequest } from "../hooks/new-http";

const ConfigApi = () => {
    
  const PostRequest = SendRequest();

  const GetConfig = async (currentUserID,companyCode) => {

    var response = await PostRequest("Config/getConfig?userID=" + currentUserID + "&companyCode=" + companyCode);
    return response;
  };

  const InitialLoadData = async (companyID, type) => {

    var response = await PostRequest("Config/InitialLoadData?companyID=" + companyID + "&type=" + type);
    return response;
  };

  return { GetConfig, InitialLoadData };
  
};


export default ConfigApi;
