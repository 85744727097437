import React, { Fragment } from "react";
import Chip from "@mui/material/Chip";


interface JBadgeProps {
    label: string;
    color: String;
    width: number;
  }


const JBadge = (props: JBadgeProps) => {

    const { label, color, width, ...other } = props;

    return <Fragment>
         <Chip
                            label={label}
                            sx={{
                              backgroundColor: color,
                              fontWeight: '600',
                              fontSize: 13,
                              width: `${width}px`,
                              color: "white",
                              fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
                            }}
                          />
    </Fragment>

}

export default JBadge;