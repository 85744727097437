import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { NavLink, useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import AuthContext from "../../store/auth-context";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBox from "@mui/icons-material/AccountBox";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import LanguageIcon from "@mui/icons-material/Language";
import { useStore } from "../../hooks-store/store";
import JCombobox from "../Layout/JCombobox";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { HdrOnSelectRounded } from "@mui/icons-material";

const drawerWidth = 260;

const PageHeader = (props) => {
  const [state, dispatch] = useStore();
  const [companyID, setCompanyID] = useState(null);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [avatarHeader, setAvatarHeader] = useState(null);
  const [anchorEl, setAnchorEl] = useState();
  const [notificationAnchorEl, setNotificationAnchorEl] = useState();
  const [languageAnchorEl, setLanguageAnchorEl] = useState();
  const [languageCode, setLanguageCode] = useState("en");
  const Language = authCtx.LanguageList;

  useEffect(() => {
    const onSelected = () => {
      if (companyID != null) {
        authCtx.setCompanyCode(
          state.company.find((x) => x.companyID === companyID).companyCode
        );
        dispatch("SetCurrentCompanyID", companyID);
      }
    };
    onSelected();
  }, [companyID]);

  const onLoad = () => {
    if (props.loadedCompanyID != null) {
      console.log(state.currentCompanyID);
      setCompanyID(props.loadedCompanyID);
      setAvatarHeader(state.currentUserName.substring(0, 1));
    }
  };

  useEffect(() => {
    onLoad();
  }, [props.loadedCompanyID]);

  const handleNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangePassword = () => {
    setAnchorEl(null);
    history.push("/changepassword");
  };

  const handleDrawerToggle = () => {
    // if (props.mobileOpen) {
    //   props.setSidebarWidth(0);
    // } else {
    //   props.setSidebarWidth(drawerWidth);
    // }

    props.setMobileOpen(!props.mobileOpen);
  };

  const Logout = () => {
    authCtx.logout();
    history.push("/login");
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const handleLanguage = (code) => {
    setLanguageCode(code);
    authCtx.changeLanguage(code);
    setLanguageAnchorEl(null);
  }

  return (
    <Fragment>
      <AppBar
        color="primary"
        sx={{
          backgroundColor: "#2D898B",
          display: "flex",
          maxWidth: "100%",
          // // { sm: `calc(100% - ${drawerWidth}px)` },
          // // ml: { sm: `${drawerWidth}px` },
        }}
        // md={{
        //   ml: { sm: `${drawerWidth}px` },
        // }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: { xs: 1 } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="button"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            VRP
          </Typography>

          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 5 }}
          >
            <MenuIcon />
          </IconButton> */}

          <JCombobox
            dataSource={state.company}
            value={companyID}
            setValue={setCompanyID}
            valueText="companyName"
            valueID="companyID"
            width="300"
            header={true}
            showCaption={false}
          />

          <IconButton
            size="large"
            aria-label="app-notification"
            aria-controls="app-notification"
            aria-haspopup="true"
            onClick={handleLanguageMenu}
            color="inherit"
          >       
              <LanguageIcon />
          </IconButton>


          <IconButton
            size="large"
            aria-label="app-notification"
            aria-controls="app-notification"
            aria-haspopup="true"
            onClick={handleNotificationMenu}
            color="inherit"
          >
            <Badge
              badgeContent={state.totalNotification}
              max={99}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#d14343",
                  fontSize: "8pt",
                  fontFamily:
                    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                  fontWeight: "600",
                },
              }}
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar sx={{ width: 28, height: 28 }}>{avatarHeader}</Avatar>
          </IconButton>

          <Menu
            id="menu-notification"
            anchorEl={notificationAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(notificationAnchorEl)}
            onClose={handleNotificationClose}
          >
            {state.notificationList.map((tripInfo, index) => (
              <NavLink
                key={tripInfo.keyId}
                name={tripInfo.title}
                to={`/trip/tripdetail?id=${tripInfo.keyId}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  padding: "5px",
                }}
              >
                <MenuItem button divider={state.notificationList.length !== index + 1}>
                  <ListItemText >
                    <Stack sx={{ flexGrow: 1 }}>
                          <Typography
                            sx={{
                              fontSize: "10pt",
                              fontWeight: "bold",
                              fontFamily:
                                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                            }}
                          >
                            {tripInfo.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10pt",
                              fontFamily:
                                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                            }}
                          >
                            {tripInfo.subtitle}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10pt",
                              fontFamily:
                                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                            }}
                          >
                            {tripInfo.reminder}
                          </Typography>
                         </Stack>

                  </ListItemText>
                </MenuItem>
              </NavLink>
            ))}
          </Menu>


          <Menu
            id="menu-language"
            anchorEl={languageAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(languageAnchorEl)}
            onClose={handleLanguageClose}
          >
            {Language.map((lang, index) => (  
                <MenuItem button onClick={() => handleLanguage(lang.code)} selected={lang.code == languageCode}>
                  <ListItemText>              
                      {lang.name}
                  </ListItemText>
                </MenuItem>
            ))}
          </Menu>


          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <AccountBox fontSize="small" />
              </ListItemIcon>
              <ListItemText>My Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={onChangePassword}>
              <ListItemIcon>
                <KeyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={Logout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default React.memo(PageHeader);
