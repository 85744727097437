import React, { Fragment, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import JButton from "./JButton";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ActionItem from "./ActionItem";
import { Typography } from "@mui/material";

export const createActionItem = (
  name,
  url,
  iconName = "",
  navLink = true,
  actionName = "",
  showConfirmation = true,
  confirmationMessage = "Are you sure you want to delete?",
  validator = [] // { "fieldname":"", "value":"", "type":"==/!=" }
) => {
  return {
    name,
    url,
    iconName,
    navLink,
    actionName,
    showConfirmation,
    confirmationMessage,
    validator
  };
};

interface JPanelProps {
  children?: React.ReactNode;
  showHeader: boolean;
  HeaderTitle: string;
  actionItemData: [];
  onActionButtonClick?: React.ForwardRefRenderFunction;
  showPanelIcon: boolean;
  panelIconName: string;
}

const JPanel = (props: JPanelProps) => {
  const {
    children,
    showHeader = false,
    HeaderTitle = "Please fill up header",
    actionItemData = [],
    showPanelIcon = false,
    panelIconName = "",
    onActionButtonClick,
    showActionItem = true
  } = props;

  const onPanelActionItemClick = (actionName, key) => {
    onActionButtonClick(actionName, key);
  };

  return (
    <Fragment>
      <Card sx={{ width: "100%" }} variant="outlined">
        {showHeader && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              pl: { xs: 2, md: 5 },
              pb: 0,
              pr: { xs: 2, md: 2 },
              pt: 2,
              color: "#6b7280",
            }}
          >
            {showPanelIcon && (
              <Icon sx={{ mr: 2, color: "#6b7280" }}>{panelIconName}</Icon>
            )}

            <Typography
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: 600,
                fontSize: "13pt",
                fontFamily:
                  'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              {HeaderTitle}
            </Typography>

            {showActionItem && (
              // <JButton aria-label="add" showIcon={showActionButtonItem} iconName={showActionButtonIcon} onClick={onClick}>
              //   {actionButtonCaption}
              <ActionItem
                id="1"
                actionItem={actionItemData}
                onActionItemClick={onPanelActionItemClick}
              />
              //</Stack></JButton>
            )}

          </Stack>
        )}
        {/* {showHeader && (<CardHeader title={HeaderTitle}
        
        avatar={
            <Icon>airport_shuttle</Icon>
        }

        sx={{ pl: { xs: 2, md: 5 },
                pb: 0, 
                fontSize: "30pt",
                fontWeight: 300,
                fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
              }}        
        action={showActionItem && (
            <JButton aria-label="add" showIcon={true} iconName="person_search" onClick={onClick}>
              Search Guest
            </JButton>
        )}>
        </CardHeader>)} */}

        <CardContent>
          <Box
            sx={{
              pl: { xs: 1, md: 3 },
              pr: { xs: 1, md: 3 },
              pb: { xs: 1, md: 3 },
              pt: { xs: 1, md: 1 },
            }}
          >
            {children}
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default React.memo(JPanel);
