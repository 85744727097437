import React, { Fragment,useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Stack } from '@mui/system'
import { Typography } from '@mui/material'

const JPhoneInput = (props) =>{

    const value = props.fieldName == null ? props.value : props.value.find(x => x.fieldName == props.fieldName).value;
    //const [value, setValue] = useState(props.fieldName == null ? props.value : props.value.find(x => x.fieldName == props.fieldName).value);

    const ChangeText = (inputValue) => {

        //setValue(inputValue);
        let valueWithprefix = inputValue;
        if (!inputValue.includes('+')) {
          valueWithprefix = '+' + inputValue;
        }

        console.log(valueWithprefix);

        if (props.fieldName == null) {
            props.setValue(valueWithprefix);
          } else {
      
            const data = props.value.find((e) => e.fieldName === props.fieldName);
            
      
            props.setValue((previous) => {
              return previous.filter((existingData) => existingData !== data);
            });
      
            data.value = valueWithprefix;
           
            props.setValue(((previous) => {
              return [...previous, data];
            }));
      
        }

    }

    return (
        <Fragment><Stack spacing={0.5}>
        <Typography sx={{
          fontWeight: "600", 
          fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
        }}>{props.caption}</Typography>
        <PhoneInput value={value} onChange={ChangeText} prefix="+" priority={{ my: 0, sg: 1 }}
            inputStyle={{ width: '300px', paddingTop: '5px', paddingBottom: '5px', height: '40px', fontSize: '12pt', 
            fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"' }}></PhoneInput> </Stack>
          </Fragment>
    )
}

export default JPhoneInput;