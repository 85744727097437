
import React, {Fragment} from 'react';
import Grid from "@mui/material/Grid";

const JFormItem = (props) => {

    return <Fragment>
            <Grid item xs={12}>
                {props.children}
            </Grid>
    </Fragment>

}

export default JFormItem;