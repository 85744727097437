import { SendRequest } from "../hooks/new-http";

const GeneralApi = () => {
    
  const PostRequest = SendRequest();

  const GetGeneralList = async (screen, companyID, optKey) => {

    var response = await PostRequest("General/GetList?screen=" + screen + "&companyID=" + companyID + "&optKey=" + optKey);
    return response;
  };

  const PostData = async (screen, data, username, companyID) => {

    const postData = { screen , data, username, companyID };

    console.log(postData);
    var response = await PostRequest("General/PostData", "POST", postData);
    return response;
  };

  const InitialForm = async (screen, companyID, key) => {

    var response = await PostRequest("General/InitialForm?screen=" + screen + "&companyID=" + companyID + "&key=" + key);
    return response;
  };

  const GeneralCall = async (url, postData) => {

    console.log(postData);
    var response = await PostRequest(url, "POST", postData);
    return response;
  };

  const GeneralGet = async (url) => {

    var response = await PostRequest(url);
    return response;
  };


  return { GetGeneralList, PostData, InitialForm, GeneralCall, GeneralGet };
};


export default GeneralApi;
