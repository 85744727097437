import { initStore } from './store';

const ConfigureStore = () => {
    const actions = {
        SetCurrentCompanyID: (curState, companyID) => {
            return { currentCompanyID: companyID };
        },
        SetCurrentUserID: (curState, userID) => {
            return { currentUserID: userID };
        },    
        SetCurrentUserName: (curState, userName) => {
            return { currentUserName: userName };
        },    
        SetSignalConnectionID: (curState, connectionID) => {
            return { signalConnectionID: connectionID };
        },    
        SetTotalNotification: (curState, totalNotification) => {
            return { totalNotification: totalNotification };
        },  
        SetNotificationList: (curState, notificationList) => {
            return { notificationList: notificationList };
        },     
    };
    initStore(actions, { companyID: 0, userName: null, userID: null, totalNotification: 0, notificationList: [], connectionID: null });
};


export default ConfigureStore;