import React, { useContext, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import AuthContext from "../store/auth-context";
import { useStore } from "../hooks-store/store";

export const SignalConnection = () => {

  const [connection, setConnection] = useState(null); 
  const [connectionID, setConnectionID] = useState(null);
  const url =  process.env.REACT_APP_API_ENDPOINT;
  const authCtx = useContext(AuthContext);
  const [state, dispatch] = useStore();

  const InitialConnect = () => {

    const connection = new HubConnectionBuilder()
      .withUrl(url + "signalHub")
      .withAutomaticReconnect()
      .build();

    if(connection) {
        console.log('Back to here?');
        connection.start()
         .then(result => {
            console.log('Connected');
            setConnectionID(connection.connectionId);
            dispatch("SetSignalConnectionID", connection.connectionId);
            //authCtx.setSignalConnectionID(connection.connectionId);

            connection.on('ReceiveNotification', notification => {
                console.log(state.signalConnectionID);  
                console.log("From ReceiveNotification");
                console.log(notification);
                dispatch("SetTotalNotification", notification.totalNotification);
                dispatch("SetNotificationList", notification.notification);
                
                // const updatedChat = [...latestChat.current];
                // updatedChat.push(message);
                // setChat(updatedChat);

            });

         })
         .catch(e => console.log('Connnection failed ', e));
    

         connection.onreconnected((connectionID) => {
          console.log("Reconnected...");
          console.log(connectionID);
            setConnectionID(connectionID);
            dispatch("SetSignalConnectionID", connectionID);
         });


        }
  
  };



  return InitialConnect;

};
