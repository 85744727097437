import { SendRequest } from "../hooks/new-http";

const UserApi = () => {
  const PostRequest = SendRequest();

  const Login = async (username, password) => {
    const postdata = {
      username,
      password,
    };

    var response = await PostRequest("User/Login", "POST", postdata);
    return response;
  };

  const ForgotPassword = async (email) => {

    const postdata = {
      email,
    };

    console.log(postdata);

    var response = await PostRequest("User/ForgotPassword", "POST", postdata);
    return response;
  };

  const GetUser = async(companyID) => {

    var response = await PostRequest("User/GetAll?companyID=" + companyID);
    return response;

  }

  const GetUserInfo = async(userID) => {

    var response = await PostRequest("User/GetUser?userID=" + userID);
    return response;

  }

  const Create = async(postdata) => {
    
    var response = await PostRequest("User/Create", "POST", postdata);
    return response;
  }

  const Update = async(postdata) => {
    
    var response = await PostRequest("User/Update", "POST", postdata);
    return response;
  }

  const ChangePassword = async (userID, password) => {

    const postdata = {
      userID, password
    };

    console.log(postdata);

    var response = await PostRequest("User/ChangePassword", "POST", postdata);
    return response;
  };

  const SignalConnect = async (userID, connectionID, companyID) => {

    const postdata = {
      userID, connectionID, companyID
    };

    var response = await PostRequest("User/SignalConnect", "POST", postdata);
    return response;
  };

  return { Login, ForgotPassword, GetUser, Create, GetUserInfo, Update, ChangePassword, SignalConnect };
};

export default UserApi;
