import React, { Fragment, useState, useEffect, useContext } from "react";
import {  NavLink } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useStore } from "../../hooks-store/store";
import Icon from "@mui/material/Icon";
import { MiddlewareArray } from "@reduxjs/toolkit";
import Translater from "../../languages/translater";
import AuthContext from "../../store/auth-context";

const FunctionalMenubar = (props) => {
  const [state, dispatch] = useStore();
  const translater = Translater();
  const [companyID, setCompanyID] = useState(state.currentCompanyID);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log(companyID);
    dispatch("SetCurrentCompanyID", companyID);
  },[companyID])

  const fontSize = authCtx.language == "cn" ? "13pt" : "11";

  const handleClick = (item) => {

    let localMenuState = state.menuState;

    const newshow = localMenuState.find((x) => x.menuid === item).show;

    localMenuState = localMenuState.filter((g) => g.menuid !== item);
    // setMenuState(x => {
    //   return x.filter(g => g.menuid !== item);
    // })

    localMenuState.push({
      menuid: item,
      show: !newshow,
    });

    dispatch("SetMenuState", localMenuState);

  };

  
  const Subscreen = (dataSource) => {
    console.log(dataSource);

    return Array.from(dataSource).map((screen, index) => (
      <div key={index}>{screen.name}</div>
    ));
  };

  
  const handleDrawerToggle = () => {
    props.setMobileOpen(false);
  };

  

  const menuitem = (subOption, sub) => {

    return (
      <div>
      <ListItem
        key={`menu_${subOption.id}`}
        sx={{ p: 1}}
        onClick={!sub ? () => handleClick(subOption.id) : null}
      >
        
        {!sub && (<Stack display="flex" direction="row" spacing={2} sx={{ pl: "9px", pt: "4px", pb: "4px", width: "240px" }}>
                <Icon sx={{ color: "#d1d5db" }}>{subOption.iconName}</Icon>
        <ListItemText
          //inset
          primary={translater.Translate(subOption.name)}
          primaryTypographyProps={{
            fontSize: fontSize,
            fontWeight: "600",
            fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            color: !sub ? "#d1d5db" : "#6b7280",
          }} />
          
        {state.menuState.find((x) => x.menuid == subOption.id).show ? (
          <ExpandLess sx={{ color: "#d1d5db" }} />
        ) : (
          <ExpandMore sx={{ color: "#d1d5db" }} />
        )}

        </Stack>)}

          {sub && (
            <ListItemText
              key={`menu_${subOption.id}`}
              //inset
              primary={translater.Translate(subOption.name)}
              primaryTypographyProps={{
                fontSize: fontSize,
                fontWeight: "600",
                fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                color: !sub ? "#d1d5db" : "#6b7280",
              }}
                />)}

      </ListItem>

      <Collapse
        //Parent menu will always show
         in={!sub ? state.menuState.find((x) => x.menuid == subOption.id).show : true}
        timeout="auto"
        unmountOnExit
      >
    
        {sub && subOption.subMenus.length > 0 && 
            (subOption.subMenus.map((submenu) => {
                return menuitem(submenu, false)
            }))
        }
        
        
        {subOption.screens
        .filter(x => x.showInMenuBar == true)
        .map((screen, index) => (
          <ListItem
          key={screen.id + 10000}
          sx={{ pl: 1, pt: 0.5, pr: 0, pb:0.5 }}>
            <NavLink
              key={screen.name}
              to={screen.url}
              style={{
                textDecoration: "none",
                fontWeight: "600",
                fontSize: fontSize,
                fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                color: "#d1d5db",
                padding: "6px 0 6px 0"
              }}
              //isActive={this.isActive}
              activeStyle={{
                backgroundColor: "rgba(255, 255, 255, 0.08)",
                color: "#10b981",
                width: "220px",
                borderRadius: "8px",
                padding: "6px 0px 6px 0px"
              }}
              onClick={handleDrawerToggle}
            >
           
             <Stack display="flex" direction="row" spacing={2} sx={{ pl: "9px", width: "240px" }}>
                {/* <Icon sx={{ color: "#d1d5db" }}>{subOption.iconName}</Icon> */}
                {/* {subOption.icon ? subOption.icon : <ImageNotSupportedIcon />} */}
                <Icon sx={{ color: "inherit" }}>{screen.iconName}</Icon>   
              <ListItemText
                 key={screen.id}
                //alignItems="center"
                primary={translater.Translate(screen.name)}
                primaryTypographyProps={{
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  fontSize: fontSize,
                  fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                  textDecoration: "none",
                }}
              /></Stack>
            </NavLink></ListItem>
        ))}

        {/* {subOption.screenName.length > 0 && <Subscreen datasource={subOption.screenName} /> } */}
      </Collapse>
    </div>

    )

  }


  return (
    <Fragment>

      <List sx={{ backgroundColor: "#121828", p: 1 }} spacing={2}>
        {state.accessRight.map((subOption) => (
          
          menuitem(subOption, true)
          // <div>
          //   <ListItem
          //     key={subOption.id}
          //     sx={{ p: 1}}
          //     button
          //     onClick={() => handleClick(subOption.id)}
          //   >
          //     <ListItemText
          //       //inset
          //       primary={subOption.name}
          //       primaryTypographyProps={{
          //         fontSize: 14,
          //         fontWeight: "medium",
          //         color: "#F0EDEE",
          //       }}
          //     />
          //     {state.menuState.find((x) => x.menuid == subOption.id).show ? (
          //       <ExpandLess sx={{ color: "#F0EDEE" }} />
          //     ) : (
          //       <ExpandMore sx={{ color: "#F0EDEE" }} />
          //     )}
          //   </ListItem>

          //   <Collapse
          //     in={state.menuState.find((x) => x.menuid == subOption.id).show}
          //     timeout="auto"
          //     unmountOnExit
          //   >
          //     {subOption.screens
          //     .filter(x => x.showInMenuBar == true)
          //     .map((screen, index) => (
          //       <div key={screen.name}>
          //         <NavLink
          //           to={screen.url}
          //           name="ABCD"
          //           style={{
          //             textDecoration: "none",
          //             color: "#F0EDEE",
          //             display: "flex",
          //             padding: "10px",
          //           }}
          //           //isActive={this.isActive}
          //           activeStyle={{
          //             backgroundColor: "#1B9AAA",
          //             color: "#F0EDEE",
          //             width: "200px",
          //             padding: "10px",
          //             borderRadius: "5px",
          //           }}
          //           onClick={handleDrawerToggle}
          //         >
          //           <ListItemIcon
          //             sx={{ color: "inherit" }}
          //             alignItems="flex-start"
          //           >
          //             {/* {subOption.icon ? subOption.icon : <ImageNotSupportedIcon />} */}
          //             <Icon>{screen.iconName}</Icon>
          //           </ListItemIcon>
          //           <ListItemText
          //             alignItems="center"
          //             primary={screen.name}
          //             primaryTypographyProps={{
          //               fontSize: 14,
          //               textDecoration: "none",
          //             }}
          //           />
          //         </NavLink>
          //       </div>
          //     ))}

          //     {/* {subOption.screenName.length > 0 && <Subscreen datasource={subOption.screenName} /> } */}
          //   </Collapse>
          // </div>
        ))}
      </List>
    </Fragment>
  );
};

// class MenuBar extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   isActive(item) {
//     console.log(item);
//   }
//   // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
//   handleClick(item) {
//     this.setState((prevState) => ({ [item]: !prevState[item] }));
//   }
//   // if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.
//   handler(children) {
//     const { classes } = this.props;
//     const { state } = this;

//     return children.map((subOption) => {
//       if (!subOption.screenName) {
//         //This is for the sub Menu

//         return (
//           <div key={subOption.name}>
//             <NavLink
//               to={subOption.url}
//               name="ABCD"
//               style={{
//                 textDecoration: "none",
//                 color: "black",
//                 display: "flex",
//                 padding: "10px",
//               }}
//               //isActive={this.isActive}
//               activeStyle={{
//                 backgroundColor: "rgba(30,30,30,0.8)",
//                 color: "white",
//                 width: "225px",
//                 padding: "6px",
//                 borderRadius: "5px",
//               }}
//             >
//               <ListItemIcon sx={{ color: "inherit" }} alignItems="flex-start">
//                 {subOption.icon ? subOption.icon : <ImageNotSupportedIcon />}
//               </ListItemIcon>
//               <ListItemText
//                 alignItems="flex-end"
//                 primary={subOption.name}
//                 primaryTypographyProps={{
//                   fontSize: 14,
//                   textDecoration: "none",
//                 }}
//               />
//             </NavLink>
//           </div>
//         );
//       }
//       return (
//         //This is for the Menu
//         <div key={subOption.name}>
//           <ListItem button onClick={() => this.handleClick(subOption.menuName)}>
//             <ListItemText
//               //inset
//               primary={subOption.menuName}
//               primaryTypographyProps={{ fontSize: 15, fontWeight: "medium" }}
//             />
//             {state[subOption.menuName] ? <ExpandLess /> : <ExpandMore />}
//           </ListItem>
//           <Collapse in={state[subOption.menuName]} timeout="auto" unmountOnExit>
//             {this.handler(subOption.screenName)}
//           </Collapse>
//         </div>
//       );
//     });
//   }
//   render() {
//     const { classes, drawerOpen, menuOptions } = this.props;
//     return (
//       <Fragment>
//         <List>
//           <ListItem
//             key="menuHeading"
//             // divider
//             // disableGutters
//           >
//             {/* <ListItemText
//                     inset
//                   primary="Nested Menu"
//                 /> */}
//           </ListItem>
//           {this.handler(menuItems)}
//         </List>
//       </Fragment>
//     );
//   }
// }

export default React.memo(FunctionalMenubar);
