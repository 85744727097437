const chinese = {
  'Username': '用户户口',
  'Password': '密码',
  'Plate Number': '车牌号码',
  'Vehicle Model': '交通款式',
  'Manufacture Year': '生产年份',
  'Color': '颜色',
  'Seat Number': '座位',
  'Owner': '车主',
  'Registered Date': '注册日期',
  'Status': '状态',
  'Updated By': '更新用户',
  'Last Updated': '更新时间',
  'Edit': '更改',
  'Delete': '删除',
  "Vehicle": '交通',
  "Maintenance Type": '维修类型',
  "Description": '描述',
  "Start Date": '开始日期',
  "End Date": '结束日期',
  "Remarks": '备注',
  "Full Name": '全名',
  "NRIC": '身份证号码',
  "Commission Rate": '佣金',
  "Nickname": '称呼',
  "Main Contact": '联系号码（主）',
  "Secondary Contact": '联系号码（副）',
  "Whatsapp ID": 'Whatsapp户口',
  "WeChat ID": '微信户口',
  "Name": '名称',
  "Contact Person": '联系负责人',
  "Contact Number": '联系号码',
  "Address": '地址',
  "Country": '国家',
  "Reference": '编号',
  "Date": '日期',
  "Total": '总数',
  "Total Commission": '佣金总数',
  "Total Claims": '报销总数',
  "Total Collected Amount": '收款总数',
  "Total Payslip": '薪水单总数',
  "Total Contra": '调和总数',
  "Amount": '数目',
  "Claims Type": '报销类型',
  "Payslip Reference": "薪水单编号",
  "Vehicle Type": "交通类型",
  "Select Driver": '请选择司机',
  "Select Partner": "请选择生意伙伴",
  "Select Corporate": "请选择公司",
  "Select Vehicle": "请选择交通",
  "Corporate": "公司",
  "Partner": "生意伙伴",
  "Driver": "司机",
  "Commission": '佣金',
  "Passenger": "顾客",

  //Trip List
  "Trip Type": "服务类型",
  "Pax": "人数",
  "Order": "预订资料",
  "Ordered By": '预约顾客',
  "Ordered Via": "预约方式",
  "Quoted Fee": "报价",
  "Outstanding": "欠款",
  "Schedule Status": "行程状态",
  "Trip Status": "状态",
  "From": '开始日期',
  "To": '结束日期',
  "Currency": "货币",
  "No of Passenger": "人数",
  "Transport Type": "交通类型",
  "Arrival / Departure": "抵达 / 出发",
  "Transport Number": "交通号码",
  "Transport Time": "时间",

  "Trip Start Date": "接送日期",
  "Trip End Date": "结束日期",
  "Pickup Time": "接送时间",
  "No of Days": "天数",
  "Itinerary": "行程记录",
  "Estimate?": "大概时间",

  "Communicate via": "联系方式",
  "Order Source": "预定方式",
  "Order By": "预定顾客",
  "Type": "顾客类型",
  "Existing Passenger": "搜索顾客",
  "New Passenger": "新增顾客",
  "Please enter Nickname, Internal Name or Contact": "请输入顾客名称或者联系号码",
  "Search Passenger Result": "搜索顾客结果",
  "Selected Passenger": "选择顾客",
  "Bill To": '收款方式',
  "Pay To": '本公司/司机',

  "Pickup Point": "接送地点",
  "Dropoff Point": "抵达地点",
  "Please add location": "请添加地点",
  "Add Location": "添加地点",
  "Others Passenger Detail": "其它顾客资料",
  "Historial Address": "之前记录",
  "Search New Address From Google": "查询新地址",
  "Search Generic Address": "搜索常用地址",
  "Enter Manual Address": "输入地址",
  "Address Type": "输入地址选项",
  "Search Result": "搜索结果",
  "Area": "地区",
  "State": "洲",

  "Edit Passenger": "更改顾客资料",
  "Edit Info": "更改预约资料",
  "Passenger Info": "顾客资料",
  "Trip Info": "预约资料",
  "Location": "接送地点",
  "Passenger Name": "顾客称呼",
  "Name Internal Used": "内用称呼",
  "Internal Name": "内用称呼",
  "Settlement": "结算",
  "Collect Deposit": "预付定金",
  "Collect Payment": "付款",
  "Collected By": "付款方式",
  "Payment Mode": "付款方式",
  "Payment Method": "付款方式",
  "Chargeable Item": "收费类型",
  "Pickup Date": "接送时间",
  "Breakdown Fee": "收费",

  //Trip List Filter
  "Pending": "等待确定",
  "Confirmed": '确定预订',
  "Follow up": "跟进预订",
  "Unplanned": "未有行程",
  "Pending Complete": "等待完成",
  "Completed": "完成",
  "Cancelled": "取消预约",

  "Pick & Drop":"接送服务",
  "Transit":"机场接送服务",
  "Tour":"旅游服务",
  "Car Rental":"租车服务",

    //Trip List Filter
    "General": "预约和顾客资料",
    "Schedule": '行程',
    "Billing": "结帐",
    "Activity Log": "更改记录",
    "Add Charges": '添加收费',
    "Add Deposit": "添加定金",
    'Add Membership': '添加会员',
    "Add Payment": "添加付款",
    "Post Invoice": "过帐发票",

    "Document #": "文件编号",
    "Transaction Code": "交易记录",

    "Print Trip Message": "显示行程讯息",
    "Trip Message": "行程讯息",
    "Print Quotation": "显示价钱单",
    "Duplicate Trip": "复制订单",
    "Confirm Trip": "确定行程",
    "Complete Trip": "完成行程",
    "Are you sure to complete this trip?":"确定完成这个行程？",
    "Cancel Trip": "取消服务",
    "Are you sure to cancel this trip?": "确定取消这个行程？",

    "Add Schedule":"添加时间表",
    "Print Driver Schedule":"显示司机行程",
    "Edit Schedule":"更改行程",
    "Edit Location":"更改地点",

    "Effective Date": "有效日期",

    //Car Pool
    "Allocated To": '安排行程',
    "Seat No.": '座位',


  //JDataGrid
  "No record found": '没有记录',
  "Confirmation Message": '确定讯息',
  "Search": "搜索",
  "No.": "#",
  "Action": "选项",  
  "Add": "新增",  
  "Save": "储存",
  "Cancel": "取消",
  "Submit": "提交",
  "Copy": "复制",
  "Back": "返回",

  //Menu
  "Dashboard": "仪表盘",
  "Rate Overview": "价钱表",
  "Reservation": "预订",
  "Trip": "订单",
  'Journal': '会计分录',
  "New Trip": "新订单",
  'Trip Detail': '订单详情',
  "Communicate Source": "联系方式",
  "Holiday": "假期",
  "Company Document": "文件设定",
  "Company": "公司单",
  "Company Info": "公司资料",
  "Generic Location": "常用地址",
  "List": "目录",
  "Maintenance": "维修记录",
  "Expenses Type": "开支类型",
  "Claims": "报销",
  "User": "用户",
  "Access Right Control": "授权",
  "Invoice": "发票",
  "OR / PV": "收据 / 付款",
  "Payslip": "司机薪水单",
  "TRANSACTION": "记录",
  "MANAGEMENT": "管理",
  "SETTING": "设定",
  "REPORT": "报表",
  "ADMINISTRATION": "用户管理",
  "Rate Plan": "价钱表",
  "Detail": "资料",
  "Claims Detail": "报销细节",
  "Driver Detail": "司机细节",
  "Settlement Detail": "结算细节",
  "Check Rate": "查价",
  "Accounting": "账目",
  'Supplier':'供应商',
  'Audit Log':'审计记录',
  'Profit Report':'收入报表',
  'Membership Type': '会员类型',


  //Dashboard
  "Revenue": "收入",
  "Expenses": "开支",
  "Profit": "利润",
  "Revenue Forecast Next 6 Months": "预测六个月收入",
  "No of Trip": "订单数目",
  "Amount to Collect": "可收数目",
  "Next 7 Days Activity": "七天行程",

  //Corporate Settlement
  "Payable Item": "可收款费用",
  "Unbilled Item": "可开发票费用",
  "Charges": "费用",
  "Invoice Number": "发票号码",
  "Post to Invoice": "生成发票",
  "Total Charges": "费用总数",

  //Claims
  "Claims Detail": "报销细节",

  //Driver Settlement
  "Total Commission": "佣金总数",
  "Total Collection": "收帐总数",
  "Collected Amount": "收帐",
  "Total Pay to Driver": "付款给司机",
  "Collect from Driver": "向司机收款",


  //Partner Settlement
  "Pay to Partner": "付款给生意伙伴",
  "Collect from Driver": "向生意伙伴收款",

  //Rate Plan
  "From": "出发地点",
  "To": "抵达地点",
  "Location Type": "地点类型",
  "Rate (From -> To)": "价钱 (出发地点 -> 抵达地点)",
  "Rate (To -> From)": "价钱 (抵达地点 -> 出发地点)",
  "Daily Rate (From -> To)": "每日价钱 (出发地点 -> 抵达地点)",
  "Daily Rate (From -> To)": "每日价钱 (抵达地点 -> 出发地点)",
  "Qty": "数量",
  "Rate": "价钱",
  "Total Amount": "总数",
  "Any Area": "任何地区",
  "Selected Area": "选择地区",
  "State Area": "县区",
  "Event Rate": "特别价",
  'Expiry Date': '截至日期',

  //Reservation
  "Reservation #": "预订号码",
  "Location Info": "地点",
  "Payment Status": "付款状态",
  "Reservation Status": "预订状态",

}

export default chinese;