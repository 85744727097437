import React, { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from '@mui/material/useMediaQuery';
import JButton from './JButton';
import { useTheme } from '@mui/material/styles';
import Translater from "../../languages/translater";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export interface ModalProps {
  title: string,
  openModal: bool, 
  setOpenModal: object,
  onConfirm: object,
  submitCaption: string,
  showDefaultButton: bool,
  width: string
}



const JModal = (props: ModalProps) => {

  const { title, openModal, setOpenModal, onConfirm, submitCaption = "Submit", showDefaultButton = true, size = "sm" } = props;    

  const translater = Translater();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onModalConfirm = () =>{
    onConfirm();
  }


  return (
    <Fragment>
      <Dialog 
       fullScreen={fullScreen}
       maxWidth={size}
      open={props.openModal} onClose={handleClose} color="secondary" sx={{ width: '100%',  p: { xs: 1, md: 5 }}}>
        <DialogTitle sx={{ fontSize:"14pt" }}>{props.title}
        
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        
        </DialogTitle>
        <DialogContent>
            {props.children}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}

        </DialogContent>
        {showDefaultButton && (<DialogActions>
          <JButton onClick={onModalConfirm}>{translater.Translate(submitCaption)}</JButton>
          <JButton onClick={handleClose} buttontype="secondary">{translater.Translate("Cancel")}</JButton>
        </DialogActions>)}
      </Dialog>
    </Fragment>
  );
};

export default JModal;
