import React, { Fragment, useState } from "react";
import JDataGrid, {
  createTableColumn,
  createActionItem,
} from "../Layout/JDataGrid";
// import JButton from "../Layout/JButton";
// import useHttp from "../../hooks/use-http";
// import VehicleApi from "../../api/vehicle-api";
// import { useStore } from "../../hooks-store/store";

const TableColumn = [
  createTableColumn("Plate Number", "plateNumber"),
  createTableColumn("Model", "model", "center"),
  createTableColumn("Manufacture Year", "manufactureYear", "center"),
  createTableColumn("Color", "color", "center"),
  createTableColumn("Seat Number", "seatNumber", "center"),
  createTableColumn("Owner", "owner", "center"),
];

const ActionItemData = [
  createActionItem("Edit", "/dashboard"),
  createActionItem("Delete", "/companylist"),
];

const Vehicle = () => {

  // const [data, setData] = useState([]);

  // useEffect(() => {

  //   async function fetchData() {
  //     var response = await vehicleApi.GetVehicleList(state.currentCompanyID);
  //     console.log(response);

  //     if (response.success) {
  //       setData(response.data);
  //     } else {
  //     }
  //   }
  //   fetchData();
  // }, [state.currentCompanyID]);

  return (
    <Fragment>
      {/* <JDataGrid
        tableColumn={TableColumn}
        dataSource={data}
        actionItemData={ActionItemData}
        url="/newvehicle"
      /> */}
    </Fragment>
  );
};

export default Vehicle;
