import React, { Fragment, useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment/moment";
import Stack from "@mui/material/Stack";
import JCombobox from "./JCombobox";

const DatePicker = (props) => {
 
  const initialValue = props.fieldName == null
  ? props.value
  : props.value.find((x) => x.fieldName == props.fieldName).value;
  const [Hours, setHours] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState("00:00");

  const GenerateTime = () => {   
      let time = []; 
      var hour;
      for(hour = 0; hour < 24; hour ++) {
        let hour_string = hour;
        if (hour < 10) {
          hour_string = "0"+ hour;
        }

        time.push({ id: hour_string + ":00", name: hour_string + ":00" });
        time.push({ id: hour_string + ":05", name: hour_string + ":05" });
        time.push({ id: hour_string + ":10", name: hour_string + ":10" });
        time.push({ id: hour_string + ":15", name: hour_string + ":15" });
        time.push({ id: hour_string + ":20", name: hour_string + ":20" });
        time.push({ id: hour_string + ":25", name: hour_string + ":25" });
        time.push({ id: hour_string + ":30", name: hour_string + ":30" });
        time.push({ id: hour_string + ":35", name: hour_string + ":35" });
        time.push({ id: hour_string + ":40", name: hour_string + ":40" });
        time.push({ id: hour_string + ":45", name: hour_string + ":45" });
        time.push({ id: hour_string + ":50", name: hour_string + ":50" });
        time.push({ id: hour_string + ":55", name: hour_string + ":55" });
      }

      setHours(time);
  }

  useEffect(useCallback(() => {
    GenerateTime();
  },[]), []);

  useEffect(useCallback(() => {
    
    const initialValue = props.fieldName == null
    ? props.value
    : props.value.find((x) => x.fieldName == props.fieldName).value;
    
    if(initialValue == null) {
      setSelectedDate(null);
      setSelectedHour("00:00");
    }
    else {        
      const tempValues = moment(initialValue).format("YYYY-MM-DD HH:mm").split(" ");
      setSelectedDate(tempValues[0]);
      setSelectedHour(tempValues[1]);
    }

  }, [props.value]), []);
   


  const handleChange = (newValue) => {
    var datevalue = moment(newValue.$d).format("YYYY-MM-DD " + selectedHour);  
    setSelectedDate(moment(newValue.$d).format("YYYY-MM-DD"));
    formatDate(datevalue);

    console.log(datevalue)
  };

  const formatDate = (datevalue) => {
   
    if (props.fieldName == null) {
      props.setValue(datevalue);
    } else {
      const data = props.value.find((e) => e.fieldName === props.fieldName);

      props.setValue((previous) => {
        return previous.filter((existingData) => existingData !== data);
      });

      data.value = datevalue;

      props.setValue((previous) => {
        return [...previous, data];
      });

      console.log(data);
    }
  }

  useEffect(() => {
    if(selectedDate != null && selectedHour != null) {
      formatDate(selectedDate + " " + selectedHour);
      console.log(selectedDate);
    }
  }, [selectedHour])


  const showTime = props.showTime == null ? true : props.showTime;

  let width = props.width == null ? "300" : props.width;
  width = showTime == true ? width - 140 : width;
  let maxDate = props.maxDate ?? null;
  let minDate = props.minDate ?? null;

  return (
    <Fragment>
          <Stack spacing={0.5}>
       <Typography sx={{
        fontWeight: "600", 
        fontSize: "11pt",
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
      }}>{props.caption}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <TextField
        id="date"
        type="date"
        size="small"
        hiddenLabel
        defaultValue={props.value}
        sx={{ width: 300 }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={DatePickerChangeHandler} */}

        {/* {showTime && (<DesktopDateTimePicker
           sx = {{ width: { xs: "100%", md: `${width}px` }}}
          value={initialValue}
          onChange={handleChange}
          inputFormat="DD/MM/YYYY hh:mm a"
          renderInput={(params) => (
            <TextField
              {...params}
              hiddenLabel
              autoComplete='off'
              size="small"
              sx = {{ width: { xs: "100%", md: `${width}px` }}}
            />
          )}
        />)} */}

      <Stack direction="row">
        <DesktopDatePicker
           sx = {{ width: { xs: "100%", md: `${width}px` }, }}
          value={selectedDate}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => (
            <TextField
              {...params}
              hiddenLabel
              autoComplete='off'
              size="small"

              sx = {{ width: { xs: "100%", md: `${width}px` }, fontSize: "10pt"}}
            />
          )}
        />

        {showTime && (<Fragment><JCombobox dataSource={Hours} value={selectedHour} setValue={setSelectedHour} valueID="id" valueText="name" width="140" showCaption={false}>
                </JCombobox>
                </Fragment>)}
        </Stack>
      </LocalizationProvider>
      </Stack>
    </Fragment>
  );
};

export default React.memo(DatePicker);
