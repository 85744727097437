import React, { Fragment, useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

const JCheckbox = (props) => {
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    setIsCheck(props.fieldName == null
      ? props.isCheck
      : props.value.find((x) => x.fieldName == props.fieldName).value);
  },[props.isCheck]);

  const OnChecked = () => {
    if (props.fieldName == null) {
      console.log(!isCheck);
      props.setIsCheck(!isCheck);
    } else {
      const data = props.value.find((e) => e.fieldName === props.fieldName);
      console.log(data);

      props.setValue((previous) => {
        return previous.filter((existingData) => existingData !== data);
      });

      data.value = !isCheck;

      props.setValue((previous) => {
        return [...previous, data];
      });

      console.log(data);
    }
    setIsCheck(!isCheck);

  };

  return (
    <Fragment>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Checkbox
          checked={isCheck}
          sx={{
            p: 0,
            color: "rgba(80,72,229,1)",
            "&.Mui-checked": {
              color: "rgba(80,72,229,1)",
            },
          }}
          onChange={OnChecked}
        />
        <Typography>{props.caption}</Typography>
      </Stack>
    </Fragment>
  );
};

export default React.memo(JCheckbox);
