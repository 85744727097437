import React, { Fragment, useEffect, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Combobox = (props) => {
  // const initialValue =
  //   props.fieldName == null
  //     ? props.value
  //     : (props.value == null ? null : props.value.find((x) => x.fieldName == props.fieldName).value);
  // //To set the default value when the combox have value
  // console.log("Combobox Loading");
  // const getDefaultOption = () =>
  //   props.dataSource.find((m) => m[props.valueID] == initialValue) ?? null;

  //const [comboxValue, setComboValue] = React.useState(props.value);
  // const [inputValue, setInputValue] = React.useState(null);

  const onChanged = (event: SelectChangeEvent) => {
    if (props.fieldName == null) {
      console.log(event.target.value);
      props.setValue(event.target.value);
    } else {
      const data = props.value.find((e) => e.fieldName === props.fieldName);
      console.log(data);

      props.setValue((previous) => {
        return previous.filter((existingData) => existingData !== data);
      });

      data.value = event.target.value;

      props.setValue((previous) => {
        return [...previous, data];
      });

      console.log(data);
    }
  };

  // //Return Combox Value to Parent
  // const comboxChangeHandler = (event, newInputValue) => {

  //   console.log("Change Value?");

  //   if (newInputValue != null) {
  //     setComboValue(newInputValue);

  //     if (props.fieldName == null) {
  //       props.setValue(newInputValue[props.valueID]);
  //     } else {
  //       const data = props.value.find((e) => e.fieldName === props.fieldName);

  //       props.setValue((previous) => {
  //         return previous.filter((existingData) => existingData !== data);
  //       });

  //       data.value = newInputValue[props.valueID];

  //       props.setValue((previous) => {
  //         return [...previous, data];
  //       });

  //       console.log(data);
  //     }
  //   } else {
  //     props.setValue(0);
  //     setComboValue(null);
  //     setInputValue(null);
  //   }
  // };

  const width = props.width == null ? "300" : props.width;
  const backgroundcolor = "white";
  const defaultText = props.defaultText ?? "To be advised";
  const defaultValue = props.defaultValue ?? 0;
  const value =
    props.fieldName == null
      ? props.value
      : props.value.find((x) => x.fieldName == props.fieldName).value;
  const header = props.header ?? false;
  const color = header ? "white" : "black";
  const showCaption = props.showCaption == null ? true : false;

  return (
    <Fragment>
      <Stack spacing={0.5}>
        {showCaption && (
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "11pt",
              fontFamily:
                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            }}
          >
            {props.caption}
          </Typography>
        )}
        <FormControl
          sx={{
            minWidth: { xs: "100%", md: `${width}px` },
            maxWidth: { xs: "100%", md: `${width}px` },
            textAlign: header ? "right" : "left",
            fontSize: "11pt",
          }}
          size="small"
        >
          <Select defaultValue={defaultValue} autoWidth={false} value={value} onChange={onChanged} sx={{ color: header ? "white" : "black",    fontSize: "11pt", }} >
            {props.showDefault && (
              <MenuItem key={0} value={defaultValue} sx={{ fontSize: "11pt", }}>
                {defaultText}
              </MenuItem>
            )}

            {props.dataSource.map((item, index) => (
              <MenuItem key={item[props.valueID]} value={item[props.valueID]}  sx={{ fontSize: "11pt", }}>
                {item[props.valueText]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <Autocomplete
          //multiple
          key={props.valueID}
          options={props.dataSource}
          sx={{
            width: { xs: "100%", md: `${width}px` },
            backgroundColor: backgroundcolor,
            fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
          }}
          getOptionLabel={(option) => option[props.valueText]}
          id="auto-complete"
          //autoComplete
          //blurOnSelect
          //includeInputInList
          onChange={comboxChangeHandler}
          value={comboxValue}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          // disableCloseOnSelect
          // renderOption={(props, option, { selected }) => (
          //     <li {...props}>
          //       {/* <Checkbox
          //         icon={icon}
          //         checkedIcon={checkedIcon}
          //         style={{ marginRight: 8 }}
          //         checked={selected}
          //       /> 
          //       {option.name}
          //     </li>
          //   )}
          renderInput={(params) => (
            <TextField
              {...params}
              hiddenLabel
              size="small"
              sx={{
                fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
              }}
            />
          )}
        /> */}
      </Stack>
    </Fragment>
  );
};

export default React.memo(Combobox);
