import React, { useState } from 'react';
import JTextbox from '../Layout/JTextbox';
import JButton from '../Layout/JButton';
import Grid from '@mui/material/Grid';

const ResetPassword = (props) =>
{
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const onSubmit = () =>{

        if(password.length < 8)
        {
            props.showNotification("Please set password at least with 8 alphanumeric", false);
            return;
        }

        if(password != confirmPassword)
        {
            props.showNotification("Please set same password on Confirm Password", false);
            return;
        }


        console.log('Hello');
    }

    return <div>
        <Grid container
        rowSpacing={2}
        columns={{ xs: 2, sm: 6 }}
        columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid item xs={4}><JTextbox caption="Password" value={password} setValue={setPassword} /></Grid>
            <Grid item xs={4}><JTextbox caption="Confirm Password" value={confirmPassword} setValue={setConfirmPassword} /></Grid>
            <Grid item xs={4}><JButton onClick={onSubmit} variant="contained">Submit</JButton></Grid>
        </Grid>     
    </div>

}

export default ResetPassword;

