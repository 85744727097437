import React, { Fragment, useState, useEffect, useCallback } from "react";
import JFormLayout from "../../Layout/JFormLayout";
import { useHistory, useLocation, useParams } from "react-router-dom";
import JFormItem from "../../Layout/JFormItem";
import JButton from "../../Layout/JButton";
import JPanel from "../../Layout/JPanel";
import JCombobox from "../../Layout/JCombobox";
import JTextbox from "../../Layout/JTextbox";
import JDatePicker from "../../Layout/JDatePicker";
import JTimePicker from "../../Layout/JTimePicker";
import JCheckbox from "../../Layout/JCheckbox";
import Stack from "@mui/system/Stack";
import GeneralApi from "../../../api/general-api";
import { useStore } from "../../../hooks-store/store";
import Translater from "../../../languages/translater";
import JPhoneInput from "../../Layout/JPhoneInput";

const MaintenanceDetail = (props) => {
  let ComponentList = [];
  const param = useParams();
  const [zstate, dispatch] = useStore();
  const location = useLocation();
  const [state, setState] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const [url, setURL] = useState([]);
  const generalApi = GeneralApi();
  const translater = Translater();
  let history = useHistory();

  const queryParams = new URLSearchParams(location.search);

  if (param.screen == "demo") {
    ComponentList = [
      {
        object: "textbox",
        caption: "I am good",
        fieldname: "textboxA",
      },
      {
        object: "textbox",
        caption: "I am bad",
        fieldname: "textboxB",
      },
      {
        object: "combobox",
        caption: "I am bad",
        fieldname: "comboid",
        dataSource: [
          {
            statusId: 1,
            statusDesc: "Active",
          },
          {
            statusId: 0,
            statusDesc: "Inactive",
          },
        ],
        valueText: "statusDesc",
        valueID: "statusId",
      },
      {
        object: "datepicker",
        caption: "I am wahahaha",
        fieldname: "datepickerid",
      },
      {
        object: "timepicker",
        caption: "I am Happy",
        fieldname: "timepickerid",
      },
    ];
  } else if (param.screen == "vehiclemodel") {
    ComponentList = [
      {
        object: "textbox",
        caption: "Name",
        fieldname: "Name",
      },
      {
        object: "combobox",
        caption: "Vehicle Type",
        fieldname: "VehicleTypeID",
        dataSource: [
          {
            statusId: 1,
            statusDesc: "Active",
          },
          {
            statusId: 2,
            statusDesc: "Inactive",
          },
          {
            statusId: 3,
            statusDesc: "Itive",
          },
        ],
        valueText: "statusDesc",
        valueID: "statusId",
      },
    ];
  }

  useEffect(
    useCallback(() => {
      const InitialForm = async () => {
        var response = await generalApi.InitialForm(
          param.screen,
          zstate.currentCompanyID,
          queryParams.get("id")
        );
        console.log(response);

        if (response.success) {
          setState(response.data.state);
          setDatasource(response.data.field);

          if (queryParams.get("id") == 0) {
            setURL(response.data.createApiURL);
          } else {
            setURL(response.data.updateApiURL);
          }
        } else {
          props.showNotification(response.message, false);
        }
      };

      InitialForm();

      // console.log(ComponentList);
      // let component = [];
      //  ComponentList.map((object, index) => {

      //     let obj = {
      //         fieldName: object.fieldname,
      //         value: null
      //     }
      //     component.push(obj);
      // });

      //  setState(component);
      //  setDatasource(ComponentList);
      //  console.log(state);
    }, []),
    []
  );

  const onSubmit = async () => {
    let result = "";
    let error = false;

    state.map((parameters, index) => {
      if (!error) {
        var autoForm = datasource.find(
          (x) => x.fieldname == parameters.fieldName
        );

        if (autoForm != null) {
          if (autoForm.isRequired) {
            console.log(parameters.value);
            if (parameters.value === null || parameters.value === "") {
              error = true;
              console.log(parameters.value);
              dispatch("ShowNotification", {
                message: "Please fill in " + autoForm.caption,
                errormessage: true,
              });
            }
          }
        }

        let para_value = "";
        console.log(parameters.fieldName);
        console.log(parameters.value);
        if (parameters.value !== null) {
          if (
            isNaN(parameters.value) ||
            parameters.value == "" ||
            parameters.value.toString().includes("+")
          ) {
            para_value = `"${parameters.value}"`;
          } else {
            para_value = parameters.value;
          }
        } else {
          para_value = parameters.value;
        }

        result = result + `"${parameters.fieldName}": ${para_value},`;
      }
      //result.push({ [parameters.fieldName] : parameters.value });
      //result = result + (index === state.length - 1 ? "" : ",");
    });

    console.log(result);
    console.log(error);
    if (!error) {
      result = result + `"UpdatedBy": "${zstate.currentUserName}",`;
      result = result + `"CompanyID": ${zstate.currentCompanyID}`;
      result = JSON.parse(`{ ${result} }`);

      console.log(error);
      var response = await generalApi.GeneralCall(url, result);

      if (response.success) {
        dispatch("ShowNotification", {
          message: response.message,
        });

        history.goBack();
      } else {
        dispatch("ShowNotification", {
          message: response.message,
          errormessage: true,
        });
      }
    }
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <JPanel>
        <JFormLayout>
          {datasource.map((obj, index) => (
            <JFormItem>
              {obj.object === "textbox" && (
                <JTextbox
                  caption={translater.Translate(obj.caption)}
                  value={state}
                  setValue={setState}
                  fieldName={obj.fieldname}
                ></JTextbox>
              )}
              {obj.object === "memo" && (
                <JTextbox
                  caption={translater.Translate(obj.caption)}
                  value={state}
                  setValue={setState}
                  fieldName={obj.fieldname}
                  multipleline={true}
                ></JTextbox>
              )}
              {obj.object === "number" && (
                <JTextbox
                  caption={translater.Translate(obj.caption)}
                  value={state}
                  setValue={setState}
                  fieldName={obj.fieldname}
                  type="number"
                ></JTextbox>
              )}
              {obj.object === "phone" && (
                <JPhoneInput
                  caption={translater.Translate(obj.caption)}
                  value={state}
                  fieldName={obj.fieldname}
                  setValue={setState}
                ></JPhoneInput>
              )}
              {obj.object === "combobox" && (
                <JCombobox
                  caption={translater.Translate(obj.caption)}
                  fieldName={obj.fieldname}
                  dataSource={obj.datasource}
                  valueText={obj.valueText}
                  valueID={obj.valueID}
                  value={state}
                  setValue={setState}
                  showDefault={obj.showDefault}
                  defaultText={obj.defaultText}
                />
              )}

              {obj.object === "datepicker" && (
                <JDatePicker
                  caption={translater.Translate(obj.caption)}
                  fieldName={obj.fieldname}
                  value={state}
                  setValue={setState}
                ></JDatePicker>
              )}
              {obj.object === "date" && (
                <JDatePicker
                  caption={translater.Translate(obj.caption)}
                  fieldName={obj.fieldname}
                  value={state}
                  setValue={setState}
                  showTime={false}
                ></JDatePicker>
              )}
              {obj.object === "timepicker" && (
                <JTimePicker
                  fieldName={translater.Translate(obj.caption)}
                  caption={obj.caption}
                  value={state}
                  setValue={setState}
                ></JTimePicker>
              )}

              
            {obj.object === "checkbox" && (
                <JCheckbox
                  caption={translater.Translate(obj.caption)}
                  fieldName={obj.fieldname}
                  value={state}
                  setValue={setState}
                ></JCheckbox>
              )}  
            </JFormItem>
          ))}

          <JFormItem>
            <Stack direction="row" spacing={2}>
              <JButton onClick={onSubmit}>
                {translater.Translate("Submit")}
              </JButton>
              <JButton buttontype="secondary" onClick={onBack}>
                {translater.Translate("Back")}
              </JButton>
            </Stack>
          </JFormItem>
        </JFormLayout>
      </JPanel>
    </Fragment>
  );
};

export default React.memo(MaintenanceDetail);
