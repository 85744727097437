import React, { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  NavLink,
  useLocation,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { useStore } from "../../hooks-store/store";
import Translater from "../../languages/translater";

const Header = (props) => {
  const translater = Translater();
  const [state, dispatch] = useStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pathnames = location.pathname.split("/").filter((x) => x);
  const match = useRouteMatch();
  const history = useHistory();
  const [menu, setMenu] = useState();
  const [subMenu, setSubMenu] = useState();
  const [parentScreen, setParentScreen] = useState();
  const [parentScreenUrl, setParentScreenUrl] = useState();
  const [screen, setScreen] = useState();

  let optKey =  queryParams.get("id") != null ? "?id=" + queryParams.get("optKey") : "";


  if (queryParams.get("tab") != null) {
    optKey = optKey + (optKey.length > 0 ? "&" : "?") + "tab=" + queryParams.get("tab");
  }

  if (queryParams.get("selectedDate") != null) {
    optKey = optKey + (optKey.length > 0 ? "&" : "?") + "selectedDate=" + queryParams.get("selectedDate");
  }
  
  if (queryParams.get("noOfDays") != null) {
    optKey = optKey + (optKey.length > 0 ? "&" : "?") + "noOfDays=" + queryParams.get("noOfDays");
  }


  // useEffect(() => {
  //   if (state.accessRight.length > 0) {
  //     var result = GetBreadCrumbs();
  //   }
  // }, [state.accessRight]);

  // const GetBreadCrumbs = () => {
  //   state.accessRight.map((menu) => {
  //     menu.screen.map((screen, index) => {
  //       if (screen.url === location.pathname) {
  //         console.log(menu.name + " " + screen.name);
  //       }
  //     });
  //   });
  // };

  const CheckAccess = () => {

    if (state.accessPage.length > 0) {
      setMenu(null);
      setSubMenu(null);
      setParentScreen(null);
      setParentScreenUrl(null);

      if (location.pathname === "/changepassword") {
        setScreen("Change Password");
      } else if (location.pathname === "/unauthorize") {
        setScreen("");
      } else {
        const accesspage = state.accessPage.find(
          (x) => x.screenUrl === location.pathname
        );
        console.log(accesspage);
        if (accesspage != null) {
          setMenu(accesspage.menu);
          setSubMenu(accesspage.submenu);
          setParentScreen(accesspage.parentScreen);
          setParentScreenUrl(accesspage.parentScreenUrl);
          setScreen(accesspage.screen);
        } else {
          // history.push("/unauthorize");
        }
      }
    }

  }

  useEffect(() => {
    CheckAccess();
  }, [location.pathname, state.accessPage]);

  return (
    <Fragment>
      <header>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {menu != null && (
            <Typography
              sx={{
                fontSize: "12pt",
                fontWeight: 500,
                fontFamily:
                  'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              {translater.Translate(menu)}
            </Typography>
          )}
          {subMenu != null && (
            <Typography
              sx={{
                fontSize: "12pt",
                fontWeight: 500,
                fontFamily:
                  'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              {translater.Translate(subMenu)}
            </Typography>
          )}
          {parentScreen != null && (
            <NavLink
              style={{
                textDecoration: "none",
                color: "blue",
                fontSize: "12pt",
                fontWeight: 500,
                fontFamily:
                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              to={`${parentScreenUrl}${optKey}`}
            >
              {translater.Translate(parentScreen)}
            </NavLink>
          )}
          <Typography
            sx={{
              fontSize: "12pt",
              fontWeight: 500,
              fontFamily:
                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            }}
          >
            {translater.Translate(screen)}
          </Typography>
        </Breadcrumbs>

        {/* {state.accessRight.map((menu, index) => {
               
         return <div>{(menu.screens).map((screen, index) => {
             
             return <div>{screen.url === location.pathname && 
             (<Breadcrumbs 
              separator={<NavigateNextIcon fontSize="small" />}
             aria-label="breadcrumb">
                <Typography color="text.primary">{menu.name}</Typography> 

                {screen.showInMenuBar == false && (
                  <NavLink   style={{
                    textDecoration: "none",
                    color: "blue",
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }} to={`${screen.parentUrl}${optKey}`}>{screen.parentScreenName}</NavLink> 
                )}


                <Typography color="text.primary">{screen.name}</Typography>
                </Breadcrumbs> )}
              </div>
  

        })
        }</div>

        
       } )} */}
      </header>
    </Fragment>
  );
};

export default Header;
